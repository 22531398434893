



















































import { defineComponent, computed, onMounted, onUnmounted } from "@vue/composition-api";

export default defineComponent({
	props: {
		dialogId: {
			type: String,
			required: true,
		},
		maxWidth: [Number, String],
		formRef: Object,
		open: Boolean,
		loading: Boolean,
		disableAction: {
			type: Boolean,
			default: false,
		},
		hideActionBar: {
			type: Boolean,
			default: false,
		},
		fullscreen: Boolean,
	},
	setup(props, { emit }) {
		/* Cycle de vie */
		onMounted(() => {
			if (!props.fullscreen) {
				makeDialogScrollable();
				window.addEventListener("resize", makeDialogScrollable);
			} else {
				const contentContainer = document.querySelector(`#${props.dialogId}`);
				if (contentContainer) {
					(contentContainer as HTMLElement).style.height = "calc(100vh - 65px)";
				}
			}
			emit("base-dialog-mounted");
		});
		onUnmounted(() => {
			if (!props.fullscreen) {
				window.removeEventListener("resize", makeDialogScrollable);
			}
			emit("base-dialog-destroyed");
		});

		/* Variables */

		/* Computed */
		const dialog = computed({
			get: function () {
				return props.open;
			},
			set: function (value) {
				emit("update:open", value);
			},
		});
		const dialogMaxWidth = computed(function () {
			return props.maxWidth ?? "500px";
		});
		const formRefProxy = computed({
			get: function () {
				return props.formRef;
			},
			set: function (value) {
				emit("update:formRef", value);
			},
		});
		const loadingProxy = computed({
			get: function () {
				return props.loading;
			},
			set: function (value) {
				emit("update:loading", value);
			},
		});

		/* Méthodes */
		const makeDialogScrollable = function () {
			// On récupère la modale
			const modalContainer = document.getElementById(`${props.dialogId}-container`);
			if (modalContainer) {
				// ...et pareil pour celui du contenu
				const contentContainer = document.querySelector(`#${props.dialogId}`);
				if (contentContainer) {
					//const contentRect = contentContainer.getBoundingClientRect();
					//const contentContainerHeight = contentRect.bottom - contentRect.top;
					// On détermine si la modale est en overflow
					const modalOverflowing =
						contentContainer.scrollHeight > contentContainer.clientHeight;

					// Si la modale est en overflow, on fixe la hauteur de content-container à celle qu'il a actuellement
					if (modalOverflowing) {
						contentContainer.setAttribute(
							"style",
							`height: ${contentContainer.scrollHeight}px`
						);
					} else {
						contentContainer.setAttribute("style", "height: auto");
					}
				}
			}
		};
		const saveAndLoad = function () {
			loadingProxy.value = true;
			emit("save-item");
		};
		const closeDialog = function () {
			emit("update:open", false);
		};

		/* Watchers */

		return {
			formRefProxy,
			dialog,
			saveAndLoad,
			dialogMaxWidth,
			loadingProxy,
			closeDialog,
		};
	},
});
