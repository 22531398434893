import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Facturations from '../views/Facturations.vue'
import Activity from '../views/Activity.vue'
import Login from "../views/Login.vue";
import Signup from "../views/Signup.vue";
import ActivateUser from "../views/ActivateUser.vue";
import UserProfile from "../views/profile/UserProfile.vue";
import NewPassword from "../views/NewPassword.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ChangePassword from "../views/ChangePassword.vue";
import Users from "../views/admin/Users.vue";
import UserView from "../views/admin/UserView.vue";
import Cotations from "../views/admin/Cotations.vue";
import Actes from "../views/admin/Actes.vue";
import Admissions from "../views/admin/Admissions.vue";
import Orientations from "../views/admin/Orientations.vue";
import UHCD from "../views/admin/UHCD.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: "/home",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/journal',
    name: 'journal',
    component: Activity,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/facturations',
    name: 'facturations',
    component: Facturations,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users/:id',
    name: 'user-view',
    component: UserView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/forfaits',
    name: 'forfaits',
    component: Cotations,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/actes',
    name: 'actes',
    component: Actes,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admissions',
    name: 'admissions',
    component: Admissions,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orientations',
    name: 'orientations',
    component: Orientations,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/uhcd',
    name: 'uhcd',
    component: UHCD,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePassword
  },
  {
    path: '/activate-user/:requestId',
    name: 'activate-user',
    component: ActivateUser
  },
  {
    path: '/my-profile',
    name: 'user-profile',
    component: UserProfile
  },
  {
    path: '/new-password/:requestId',
    name: 'new-password',
    component: NewPassword
  },
]

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  // on enregistre l'url source
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    const storedState = localStorage.getItem("storeRhea");
    if (storedState) {
      const parsedState = JSON.parse(storedState);
      if (
        parsedState.loggedUser.token == "" ||
        parsedState.loggedUser.username == ""
      ) {
        next({
          path: "/login",
        });
      } else {
        next();
      }
    } else {
      next({
        path: "/login",
      });
    }
  } else {
    next();
  }
});

export default router
