import Acte from "../actes/Acte.model";
import Clinic from "../clinics/Clinic.model";
import Cotation from "../cotations/Cotation.model";
import BaseObject from "../general/BaseObject.model";
import PricedAction from "../general/PricedAction.model";
import UhcdForm from "./UhcdForm.model";

export default class Facturation extends BaseObject {
  public paymentDate: string;
  public lotNumber: number;
  public invoiceNumber: number | null;
  public caisse: string;
  public act: PricedAction | null;
  public actFrom: string;
  public actTo: string;
  public price: number;

  constructor(og?: Facturation) {
    super(og);
    this.paymentDate = og && og.paymentDate ? og.paymentDate : '';
    this.lotNumber = og && og.lotNumber ? og.lotNumber : 0;
    this.invoiceNumber = og && og.invoiceNumber ? og.invoiceNumber : null;
    this.caisse = og && og.caisse ? og.caisse : '';
    this.act = og && og.act ? og.act : null;
    this.actFrom = og && og.actFrom ? og.actFrom : '';
    this.actTo = og && og.actTo ? og.actTo : '';
    this.price = og && og.price ? og.price : 0;
  }
}