










import { defineComponent } from "@vue/composition-api";
import TypesFacturation from "@/models/enums/TypesFacturation.enum";

export default defineComponent({
	props: {
		facturationType: {
			type: Number,
			required: true,
		},
	},
	setup() {
		return {
			TypesFacturation,
		};
	},
});
