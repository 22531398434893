






















































































































































































































































































































































































































import {
	computed,
	defineComponent,
	onMounted,
	ref,
	toRef,
	toRefs,
	watch,
} from "@vue/composition-api";
import { ActivitiesCRUD } from "@/composables/activities/activities.crud";
import ConfirmDialog from "@/components/general/ConfirmDialog.vue";
import BaseDialog from "@/components/general/BaseDialog.vue";
import Activite from "@/models/activite/Activite.model";
import store from "@/store";
import User from "@/models/user/User.model";
import Clinic from "@/models/clinics/Clinic.model";
import TypesFacturation from "@/models/enums/TypesFacturation.enum";
import Cotation from "@/models/cotations/Cotation.model";
import Acte from "@/models/actes/Acte.model";
import { CotationsCRUD } from "@/composables/cotations/cotations.crud";
import { ActesCRUD } from "@/composables/actes/actes.crud";
import TypeActe from "@/models/actes/TypeActe.model";
import PricedAction from "@/models/general/PricedAction.model";
import ActeTypes from "@/models/enums/ActeTypes.enum";
import Holidays from "date-holidays";

export default defineComponent({
	components: {
		ConfirmDialog,
		BaseDialog,
	},
	props: {
		open: Boolean,
		item: {
			type: Object,
			required: true,
		},
	},
	setup(props, { emit, root }) {
		/* Import des API */
		const { activitiesData, activitiesCrud } = ActivitiesCRUD();
		const { cotationsData, cotationsCrud } = CotationsCRUD();
		const { actesData, actesCrud } = ActesCRUD();

		/* Cycle de vie */
		onMounted(() => {
			if (props.item._id) {
				editedItem.value = JSON.parse(JSON.stringify(props.item as Activite));
			} else {
				const defaultCds = loggedUser.value.clinics.filter(
					(clinic: Clinic) => clinic.isDefault
				)[0];
				if (defaultCds) {
					editedItem.value.cdsId = defaultCds.id;
				}
			}
			cotationsCrud.fetchCotations({ paginate: false }).then(() => {
				if (editedItem.value.type == TypesFacturation.FORFAIT) {
					const billedCodes = editedItem.value.billedList.map(
						(a: PricedAction) => a.code
					);
					billedForfaits.value = cotationsData.cotations.filter(
						(c: Cotation) => billedCodes.indexOf(c.code) > -1
					);
				}
			});
			actesData.pagination.sortBy = ["shortName"];
			actesCrud.fetchActes({ paginate: false }).then(() => {
				const billedCodes = editedItem.value.billedList.map((a: PricedAction) => a.code);
				if (editedItem.value.type == TypesFacturation.ACTE) {
					billedActes.value = actesData.actes.filter(
						(a: Acte) => billedCodes.indexOf(a.code) > -1
					);
				} else {
					SU2Actes.value = actesData.actes.filter((a: Acte) => a.isBillable);
					selectedSU2Actes.value = actesData.actes.filter(
						(a: Acte) => billedCodes.indexOf(a.code) > -1
					);
				}
			});
			const dateString = new Date(editedItem.value.treatedAt).toLocaleDateString();
			const timeString = new Date(editedItem.value.treatedAt).toLocaleTimeString();
			const dateArray = dateString.split("/");
			const timeArray = timeString.split(":");
			day.value = dateArray[0];
			month.value = dateArray[1];
			year.value = dateArray[2];
			hours.value = timeArray[0];
			minutes.value = timeArray[1];
			setTimeout(function () {
				dialogMounted.value = true;
			}, 10);
		});

		/* Variables */
		const editedItem = ref(new Activite());
		const openConfirmDialog = ref(false);
		const cimsString = ref("");
		const day = ref("");
		const month = ref("");
		const year = ref("");
		const hours = ref("");
		const minutes = ref("");
		const selectedType = ref(null as TypeActe | null);
		const selectedSubType = ref(null as TypeActe | null);
		const dialogMounted = ref(false);
		const openDatePicker = ref(false);
		const openTimePicker = ref(false);
		const prevBillingType = ref(null as number | null);
		const newBillingType = ref(null as number | null);
		const confirmBillingTypeChangeDialog = ref(false);
		const alertMaxActesSelected = ref(false);
		const totalActes = ref(0);
		const billedActes = ref(new Array<Acte>()); // Actes sélectionnés dans la dropdown
		const billedForfaits = ref(new Array<Cotation>()); // forfaits sélectionnés dans la dropdown
		const selectedActes = ref(new Array<PricedAction>());
		const selectedForfaits = ref(new Array<PricedAction>());
		const showSU2Actes = ref(false);
		const SU2Actes = ref(new Array<Acte>());
		const selectedSU2Actes = ref(new Array<Acte>());
		const lockConfirmation = ref(false);
		const hd = new Holidays();

		/* Computed */
		const openProxy = computed({
			get: function () {
				return props.open;
			},
			set: function (value: boolean) {
				emit("update:open", value);
			},
		});
		// au format YYYY-MM-DD
		const dateString = computed({
			get: function () {
				return `${year.value}-${month.value}-${day.value}`;
			},
			set: function (value: string) {
				const array = value.split("-");
				if (array.length == 3) {
					year.value = array[0];
					month.value = array[1];
					day.value = array[2];
					// Si on sélectionne un jour férié, on ajoute automatiquement le forfait SUF
					const codesArray = selectedForfaits.value.map((f: PricedAction) => f.code);
					const SUF = cotationsData.cotations.filter((c: Cotation) => c.code == "SUF")[0];
					// Idem si dans le WE
					const dayDate = new Date(value);
					const dayIsWeekend =
						dayDate.getDay() == 0 ||
						(dayDate.getDay() == 6 && parseInt(hours.value) >= 12);
					// Récupération des jours fériés de france (y compris les jours des dom-tom et d'alsace)
					hd.init("FR");
					const dateIsHoliday = hd.isHoliday(new Date(value).toISOString());
					if (
						SUF &&
						codesArray.indexOf(SUF.code) < 0 &&
						editedItem.value.type == TypesFacturation.FORFAIT &&
						(dateIsHoliday || dayIsWeekend)
					) {
						billedForfaits.value.push(SUF);
					}
				}
			},
		});
		// au format HH:mm
		const timeString = computed({
			get: function () {
				return `${hours.value}:${minutes.value}`;
			},
			set: function (value: string) {
				const array = value.split(":");
				if (array.length == 2) {
					hours.value = array[0];
					minutes.value = array[1];
					const codesArray = selectedForfaits.value.map((f: PricedAction) => f.code);
					// Si on est entre 22h et 8h, on ajoute le forfait SUN s'il n'est pas déjà présent
					const hoursValue = parseInt(hours.value);
					if (!isNaN(hoursValue)) {
						const SUN = cotationsData.cotations.filter(
							(c: Cotation) => c.code == "SUN"
						)[0];
						const SUF = cotationsData.cotations.filter(
							(c: Cotation) => c.code == "SUF"
						)[0];
						const before8am = hoursValue < 8;
						const after10pm = hoursValue >= 22;
						const after8pm = hoursValue >= 20;
						const before10pm = hoursValue < 22;
						if (
							SUN &&
							before8am &&
							after10pm &&
							codesArray.indexOf(SUN.code) < 0 &&
							editedItem.value.type == TypesFacturation.FORFAIT
						) {
							billedForfaits.value.push(SUN);
						}
						if (
							SUF &&
							after8pm &&
							before10pm &&
							codesArray.indexOf(SUF.code) < 0 &&
							editedItem.value.type == TypesFacturation.FORFAIT
						) {
							billedForfaits.value.push(SUF);
						}
					}
				}
			},
		});
		const loggedUser = computed(function () {
			return store.getters.loggedUser as User;
		});
		const filteredActes = computed(function () {
			let result = JSON.parse(JSON.stringify(actesData.actes)) as Acte[];
			if (selectedType.value) {
				result = result.filter((a: Acte) => a.type == selectedType.value?.value);
			}
			if (selectedSubType.value) {
				result = result.filter((a: Acte) => a.subtype == selectedSubType.value?.value);
			}
			if (editedItem.value.cdsId) {
				const cds = loggedUser.value.clinics.filter(
					(c: Clinic) => c.id == editedItem.value.cdsId
				)[0];
				if (cds && !cds.paysJusticeActs) {
					// Si le CDS ne paie pas les fraids de justice, on ne les présente pas dans la liste
					result = result.filter((a: Acte) => a.type != ActeTypes.JUSTICE);
				}
			}
			return result;
		});
		const filteredActeTypes = computed(function () {
			if (editedItem.value.cdsId) {
				const cds = loggedUser.value.clinics.filter(
					(c: Clinic) => c.id == editedItem.value.cdsId
				)[0];
				if (cds && !cds.paysJusticeActs) {
					// Si le CDS ne paie pas les fraids de justice, on ne les présente pas dans la liste
					return actesData.typesList.filter(
						(type: { value: string; text: string }) => type.value != ActeTypes.JUSTICE
					);
				} else {
					return actesData.typesList;
				}
			} else {
				return actesData.typesList;
			}
		});
		const totalForfaits = computed(function () {
			let result = 0;
			if (selectedForfaits.value) {
				selectedForfaits.value.forEach((f: PricedAction) => {
					result += f.price as number;
				});
			}
			return result;
		});
		const isMobile = computed(function () {
			return root.$vuetify.breakpoint.xs;
		});

		/* Méthodes */
		const closeDialog = function () {
			editedItem.value = new Activite();
			openProxy.value = false;
		};
		const saveItem = function () {
			if (editedItem.value.type == TypesFacturation.FORFAIT) {
				editedItem.value.billedList.length = 0;
				editedItem.value.billedList = selectedForfaits.value;
				editedItem.value.totalBilled = totalForfaits.value;
			} else if (editedItem.value.type == TypesFacturation.ACTE) {
				editedItem.value.billedList.length = 0;
				editedItem.value.billedList = selectedActes.value;
				editedItem.value.totalBilled = totalActes.value;
			}
			if (dateString.value && timeString.value) {
				const treatmentDateTime = new Date(`${dateString.value}T${timeString.value}:00`);
				editedItem.value.treatedAt = treatmentDateTime.toISOString();
			}
			let action;
			if (editedItem.value._id) {
				action = activitiesCrud.updateActivity;
			} else {
				editedItem.value.userId = loggedUser.value._id as string;
				action = activitiesCrud.addActivity;
			}
			action(editedItem.value).then(() => {
				closeDialog();
			});
		};
		const deleteItem = function () {
			activitiesCrud.deleteActivity(editedItem.value._id).then(() => {
				closeDialog();
			});
		};
		const emptyBilledList = function () {
			if (prevBillingType.value == TypesFacturation.FORFAIT) {
				billedForfaits.value = new Array<Cotation>();
			} else if (prevBillingType.value == TypesFacturation.ACTE) {
				billedActes.value = new Array<Acte>();
			}
		};
		const lockDossier = function () {
			activitiesCrud.lockActivity(editedItem.value._id, true).then(() => {
				closeDialog();
			});
		};

		/* Watchers */
		watch(
			() => editedItem.value.type,
			(newValue: number | null, oldValue: number | null) => {
				if (
					dialogMounted.value &&
					newValue != oldValue &&
					((newValue == TypesFacturation.ACTE && selectedForfaits.value.length > 0) ||
						(newValue == TypesFacturation.FORFAIT && selectedActes.value.length > 0)) &&
					// Conditions pour éviter le déclenchement de l'avertissement lors du cancel de la modale
					(prevBillingType.value == null || prevBillingType.value == oldValue) &&
					(newBillingType.value == null || newBillingType.value == newValue)
				) {
					prevBillingType.value = oldValue;
					newBillingType.value = newValue;
					confirmBillingTypeChangeDialog.value = true;
				}
			}
		);
		watch(
			() => billedActes.value,
			(newValue: Acte[]) => {
				selectedActes.value = newValue.map((a: Acte) => new PricedAction(a));
			}
		);
		watch(
			() => billedForfaits.value,
			(newValue: Cotation[]) => {
				const codesArray = newValue.map((e: Cotation) => e.code);
				if (codesArray.indexOf("SU2") > -1) {
					// Si on a un forfait SU2 sélectionné, on affiche la liste des actes facturables SU2.
					SU2Actes.value = actesData.actes.filter((a: Acte) => a.isBillable);
					showSU2Actes.value = true;
				}
				selectedForfaits.value = newValue.map((f: Cotation) => new PricedAction(f));
			}
		);
		watch(
			() => selectedActes.value,
			(newValue: PricedAction[], oldValue: PricedAction[]) => {
				const codesArray = newValue.map((a: PricedAction) => a.code);
				let actesMaxReached = false;
				totalActes.value = 0;
				// Si retrait d'un acte de la liste, on remet le flag halfOff à false
				if (newValue.length < oldValue.length) {
					selectedActes.value.forEach((a: PricedAction) => {
						a.halfOff = false;
					});
				}
				// Vérification de la présence d'une consultation U03 ou U45
				const nbConsults = codesArray.filter(
					(code: string) => code == "U03" || code == "U45"
				).length;
				const codesJustice = actesData.actes
					.filter((a: Acte) => a.type == ActeTypes.JUSTICE)
					.map((e: Acte) => e.code);
				const nbJustice = codesArray.filter(
					(code: string) => codesJustice.indexOf(code) > -1
				).length;
				// Vérification de la présence de deux actes supplémentaires max
				actesMaxReached = codesArray.length - nbConsults - nbJustice > 2;
				if (actesMaxReached) {
					// Si nb max actes atteint, on affiche la modale d'info et on retire le dernier élément ajouté dans le tableau
					billedActes.value.pop();
					alertMaxActesSelected.value = true;
				} else {
					// Sinon on descend le prix du second acte de 50% (le moins cher)
					const actesNoConsultNoJustice = newValue.filter(
						(a: PricedAction) =>
							a.code != "U03" && a.code != "U45" && codesJustice.indexOf(a.code) < 0
					);
					if (actesNoConsultNoJustice.length == 2) {
						actesNoConsultNoJustice.sort((a: PricedAction, b: PricedAction) => {
							if ((a.price as number) > (b.price as number)) return 1;
							return -1;
						});
						const cheaperActe = actesNoConsultNoJustice[0];
						cheaperActe.halfOff = true;
						cheaperActe.price = parseFloat(
							(Math.floor(100 * (cheaperActe.price / 2)) / 100).toFixed(2)
						);
						newValue.forEach((a: PricedAction) => {
							if (a.code == cheaperActe.code) {
								totalActes.value += cheaperActe.price;
							} else {
								totalActes.value += a.price;
							}
						});
					} else {
						newValue.forEach((a: PricedAction) => {
							totalActes.value += a.price;
						});
					}
				}
			}
		);
		watch(
			() => selectedSU2Actes.value,
			(newValue: Acte[]) => {
				selectedForfaits.value = billedForfaits.value.map(
					(e: Cotation) => new PricedAction(e)
				);
				newValue.forEach((su2: Acte) => {
					selectedForfaits.value.push({
						name: su2.shortName,
						shortName: "",
						code: su2.code,
						price: 0,
						halfOff: false,
					});
				});
			}
		);
		watch(
			() => editedItem.value.cdsId,
			(newValue: string) => {
				selectedType.value = null;
				// Si le nouveau CDS sélectionné ne paie pas les frais de justice, on les retire des actes saisis
				if (newValue) {
					const cds = loggedUser.value.clinics.filter(
						(c: Clinic) => c.id == editedItem.value.cdsId
					)[0];
					if (cds && !cds.paysJusticeActs) {
						billedActes.value = billedActes.value.filter(
							(a: Acte) => a.type != ActeTypes.JUSTICE
						);
					}
				}
			}
		);

		return {
			...toRefs(activitiesData),
			openProxy,
			closeDialog,
			editedItem,
			saveItem,
			deleteItem,
			openConfirmDialog,
			cimsString,
			loggedUser,
			day,
			month,
			year,
			hours,
			minutes,
			TypesFacturation,
			selectedForfaits,
			selectedActes,
			loadingActes: toRef(actesData, "loading"),
			filteredActes,
			actesCriteria: toRef(actesData, "criteria"),
			loadingForfaits: toRef(cotationsData, "loading"),
			forfaits: toRef(cotationsData, "cotations"),
			selectedType,
			filteredActeTypes,
			selectedSubType,
			totalActes,
			totalForfaits,
			isMobile,
			openDatePicker,
			openTimePicker,
			dateString,
			timeString,
			prevBillingType,
			newBillingType,
			confirmBillingTypeChangeDialog,
			emptyBilledList,
			alertMaxActesSelected,
			billedActes,
			billedForfaits,
			SU2Actes,
			showSU2Actes,
			selectedSU2Actes,
			lockConfirmation,
			lockDossier,
		};
	},
});
