export default class TypeActe {
  public value: string;
  public text: string;
  public subtypes: TypeActe[];

  constructor() {
    this.value = "";
    this.text = "";
    this.subtypes = new Array<TypeActe>();
  }
}