var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.status == _vm.ActivityStatus.NEW)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"small":"","color":"blue","dark":""}},'v-chip',attrs,false),on),[_vm._v(" Nouveau ")])]}}],null,false,254852202)},[_c('span',[_vm._v(" Ce dossier n'a pas encore été pointé dans un relevé d'activité fourni par la Sécurité Sociale ")])]):_vm._e(),(_vm.status == _vm.ActivityStatus.PAID)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"small":"","color":"green","dark":""}},'v-chip',attrs,false),on),[_vm._v(" Payée ")])]}}],null,false,3369951512)},[_c('span',[_vm._v("Ce dossier a été payé par la Sécurité Sociale")])]):_vm._e(),(_vm.status == _vm.ActivityStatus.PARTIALLY_PAID)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"small":"","color":"orange","dark":""}},'v-chip',attrs,false),on),[_vm._v(" Partiellement payée ")])]}}],null,false,1208525087)},[_c('span',[_vm._v("Ce dossier a été partiellement payé par la Sécurité Sociale")])]):_vm._e(),(_vm.status == _vm.ActivityStatus.REJECTED)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"small":"","color":"red","dark":""}},'v-chip',attrs,false),on),[_vm._v(" Paiement rejeté ")])]}}],null,false,3051287870)},[_c('span',[_vm._v("Le paiement de ce dossier a été rejeté par la Sécurité Sociale")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }