







































































































































import { computed, defineComponent, onMounted, ref, toRefs } from "@vue/composition-api";
import { ActesCRUD } from "@/composables/actes/actes.crud";
import ConfirmDialog from "@/components/general/ConfirmDialog.vue";
import BaseDialog from "@/components/general/BaseDialog.vue";
import Acte from "@/models/actes/Acte.model";

export default defineComponent({
  components: {
    ConfirmDialog,
    BaseDialog,
  },
  props: {
    open: Boolean,
    item: {
      type: Object,
      required: true,
    },
    updateMode: Boolean,
    createMode: Boolean,
  },
  setup(props, { emit }) {
    /* Import des API */
    const { actesData, actesCrud } = ActesCRUD();

    /* Cycle de vie */
    onMounted(() => {
      if (props.updateMode) {
        editedItem.value = JSON.parse(JSON.stringify(props.item as Acte));
        cimsString.value = editedItem.value.listCims.join(",");
      }
    });

    /* Variables */
    const editedItem = ref(new Acte());
    const openConfirmDialog = ref(false);
    const cimsString = ref("");

    /* Computed */
    const openProxy = computed({
      get: function () {
        return props.open;
      },
      set: function (value: boolean) {
        emit("update:open", value);
      },
    });
    const subtypesList = computed(function () {
      const typeSelected = editedItem.value.type;
      let result = new Array<any>();

      if (typeSelected) {
        const matchingType = actesData.typesList.filter(
          (type: any) => type.value == typeSelected
        )[0];
        if (matchingType) {
          result = matchingType.subtypes;
        }
      }

      return result;
    });

    /* Méthodes */
    const closeDialog = function () {
      emit("update:createMode", false);
      emit("update:updateMode", false);
      editedItem.value = new Acte();
      openProxy.value = false;
    };
    const saveItem = function () {
      cimsString.value.replaceAll(" ", "");
      if (cimsString.value.length == 0) {
        editedItem.value.listCims = new Array<string>();
      } else {
        editedItem.value.listCims = cimsString.value.split(",");
      }
      editedItem.value.price = parseFloat(editedItem.value.price as string);
      if (editedItem.value.isBillable == null) {
        editedItem.value.isBillable = false;
      }
      if (props.createMode) {
        actesCrud.addActe(editedItem.value).then(() => {
          closeDialog();
        });
      } else if (props.updateMode) {
        actesCrud.updateActe(editedItem.value).then(() => {
          closeDialog();
        });
      }
    };
    const deleteItem = function () {
      actesCrud.deleteActe(editedItem.value._id).then(() => {
        closeDialog();
      });
    };

    return {
      ...toRefs(actesData),
      openProxy,
      closeDialog,
      editedItem,
      saveItem,
      deleteItem,
      openConfirmDialog,
      subtypesList,
      cimsString,
    };
  },
});
