import User from '@/models/user/User.model'
import Vue from 'vue'
import Vuex from 'vuex'
import { useObjectUtils } from "@/composables/utils/object";
import ApiError from '@/models/general/ApiError.model';

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    loggedUser: new User(),
    apiMessage: new ApiError,
    openApiMessage: false,
    title: "",
    icon: ""
  },
  getters: {
    loggedUser: (state) => state.loggedUser,
    apiMessage: (state) => state.apiMessage,
    openApiMessage: (state) => state.openApiMessage,
    title: (state) => state.title,
    icon: (state) => state.icon
  },
  mutations: {
    initialiseStore(state) {
      const storedStateString = localStorage.getItem("storeRhea");
      const { deepMerge } = useObjectUtils();
      // Check if the ID exists
      // Replace the state object with the stored item
      const storeState = storedStateString ? JSON.parse(storedStateString) : state;
      const newState = storedStateString ? deepMerge(state, storeState) : state;
      this.replaceState(newState);
    },
    setLoggedUser: function (state, user: User) {
      state.loggedUser = user;
    },
    setApiMessage: function (state, message: ApiError) {
      state.apiMessage = message;
    },
    setOpenApiMessage: function (state, value: boolean) {
      state.openApiMessage = value;
    },
    setTitle: function (state, value: string) {
      state.title = value;
    },
    setIcon: function (state, value: string) {
      state.icon = value;
    }
  },
  actions: {
  },
  modules: {
  }
});

// Subscribe to store updates
store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  try {
    localStorage.setItem("storeRhea", JSON.stringify(state));
  } catch (e) {
    localStorage.clear();
    localStorage.setItem("storeRhea", JSON.stringify(state));
  }
});

export default store;
