import ApiFilterTypes from "../enums/ApiFilterTypes.enum";
import BaseCriteria from "../general/BaseCriteria.model";

export default class ActiviteCriteria extends BaseCriteria {
  public treatedAt: string | null;
  public dossier: string | null;
  public type: string | null;
  public userId: string | null;
  public cdsId: string | null;
  public status: string | null;
  public locked: boolean | null;

  constructor() {
    super();
    this.treatedAt = null;
    this.dossier = null;
    this.type = null;
    this.userId = null;
    this.cdsId = null;
    this.status = null;
    this.locked = null;

    this.initFields();
  }

  initFields() {
    this.fields = [
      { name: "treatedAt", type: ApiFilterTypes.DATE },
      { name: "dossier", type: ApiFilterTypes.TEXT },
      { name: "type", type: ApiFilterTypes.NUMBER },
      { name: "userId", type: ApiFilterTypes.TEXT },
      { name: "cdsId", type: ApiFilterTypes.TEXT },
      { name: "status", type: ApiFilterTypes.TEXT },
      { name: "locked", type: ApiFilterTypes.BOOL },
    ]
  }
}