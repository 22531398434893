










































































































































import { ref, toRefs, defineComponent } from "@vue/composition-api";
import { useLogin } from "@/composables/general/login";
import User from "../models/user/User.model";

export default defineComponent({
	name: "Login",
	setup() {
		/* Import des API */
		const { loginData, signUpUser, resendActivationLink, validateEmail } = useLogin();

		/* Cycle de vie */

		/* Variables */
		const dialog = ref(true);
		const newUser = ref(new User());
		const confirmPassword = ref("");
		const showConfirmPswdAlert = ref(false);
		const showActivationOkMessage = ref(false);
		const showResendActivationForm = ref(false);
		const newActivationEmail = ref("");

		/* Computed */

		/* Méthodes */
		const createUser = function () {
			if (confirmPassword.value == newUser.value.password) {
				showConfirmPswdAlert.value = false;
				signUpUser(newUser.value)
					.then(() => {
						showActivationOkMessage.value = true;
					})
					.catch(() => {
						newUser.value.email = "";
						newUser.value.password = "";
						confirmPassword.value = "";
					});
			} else {
				showConfirmPswdAlert.value = true;
			}
		};
		const sendActivationEmail = function () {
			showResendActivationForm.value = true;
			resendActivationLink(newActivationEmail.value).then(() => {
				showResendActivationForm.value = false;
				showActivationOkMessage.value = true;
			});
		};

		/* Watchers */

		return {
			dialog,
			createUser,
			...toRefs(loginData),
			confirmPassword,
			newUser,
			showConfirmPswdAlert,
			showActivationOkMessage,
			sendActivationEmail,
			showResendActivationForm,
			newActivationEmail,
			validateEmail,
		};
	},
});
