



























































































































import CreateUpdateActeDialog from "@/components/actes/CreateUpdateActeDialog.vue";
import { defineComponent, onMounted, ref, toRefs, watch } from "@vue/composition-api";
import { ActesCRUD } from "@/composables/actes/actes.crud";
import BaseDatatable from "@/components/general/BaseDatatable.vue";
import Acte from "@/models/actes/Acte.model";
import ActesFilters from "@/components/actes/ActesFilters.vue";

export default defineComponent({
	components: {
		CreateUpdateActeDialog,
		BaseDatatable,
		ActesFilters,
	},
	setup() {
		/* Import des API */
		const { actesData, actesCrud } = ActesCRUD();

		/* Cycle de vie */
		onMounted(() => {
			actesData.firstLoad = true;
			actesCrud.fetchActes({ paginate: true });
		});

		/* Variables */
		const openDialog = ref(false);
		const createMode = ref(false);
		const updateMode = ref(false);
		const editedActe = ref(new Acte());
		const headers = [
			{ text: "Nom", value: "shortName", width: "350px" },
			{ text: "Code CCAM", value: "code" },
			{ text: "Chapitre", value: "type" },
			{ text: "Paragraphe", value: "subtype" },
			{ text: "Prix", value: "price" },
			{ text: "Facturable SU2", value: "isBillable" },
			{ text: "CIM associés", value: "cims", sortable: false },
			{ text: "Commentaires", value: "notes", width: "50px" },
		];
		const openFilters = ref(false);

		/* Méthodes */
		const openDialogAsCreate = function () {
			createMode.value = true;
			openDialog.value = true;
		};
		const openDialogAsUpdate = function (acte: Acte) {
			updateMode.value = true;
			editedActe.value = acte;
			openDialog.value = true;
		};
		const getTypeLabel = function (type: string) {
			const matchingType = actesData.typesList.filter(
				(acteType: { text: string; value: string }) => acteType.value == type
			)[0];
			if (matchingType) {
				return matchingType.text;
			} else {
				return "-";
			}
		};
		const getSubtypeLabel = function (type: string, itemSubtype: string) {
			const matchingType = actesData.typesList.filter(
				(acteType: {
					text: string;
					value: string;
					subtypes: { text: string; value: string }[];
				}) => acteType.value == type
			)[0];
			if (matchingType) {
				const subtypes = matchingType.subtypes;
				const matchingSubtypes = subtypes.filter(
					(subtype: { text: string; value: string }) => subtype.value == itemSubtype
				)[0];
				if (matchingSubtypes) {
					return matchingSubtypes.text;
				} else {
					return "-";
				}
			} else {
				return "-";
			}
		};
		const applyFilters = function () {
			actesCrud.fetchActes({ paginate: true });
		};
		const highlightTerm = function (original: string, term: string) {
			if (term) {
				return original.replace(new RegExp(term, "ig"), function (match: string) {
					return `<span class="highlighted-search">${match}</span>`;
				});
			} else {
				return original;
			}
		};

		/* Watchers */
		watch(
			() => actesData.pagination,
			() => {
				if (!actesData.firstLoad) {
					actesCrud.fetchActes({ paginate: true });
				}
			},
			{ deep: true }
		);
		watch(
			() => openDialog.value,
			(newValue: boolean, oldValue: boolean) => {
				if (!newValue && oldValue) {
					actesCrud.fetchActes({ paginate: true });
				}
			}
		);

		return {
			openDialog,
			createMode,
			updateMode,
			editedActe,
			openDialogAsCreate,
			openDialogAsUpdate,
			...toRefs(actesData),
			headers,
			getTypeLabel,
			getSubtypeLabel,
			openFilters,
			applyFilters,
			highlightTerm,
		};
	},
});
