





























































import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import ActivityUploader from "@/components/activity/ActivityUploader.vue";
import useXLSImportUtils from "@/composables/utils/xlsImports";

export default defineComponent({
	components: {
		ActivityUploader,
	},
	props: {
		open: {
			type: Boolean,
			required: true,
		},
	},
	setup(props, { emit }) {
		/* APIs */
		const { generateEmptyJournalCSVFile } = useXLSImportUtils();

		/* Variables */
		const loadingImport = ref(false);
		const csvFile = ref(null as File | null);

		/* Computed */
		const openProxy = computed({
			get: function () {
				return props.open as boolean;
			},
			set: function (value: boolean) {
				emit("update:open", value);
			},
		});

		/* Watchers */
		watch(
			() => csvFile.value,
			(newValue: File | null) => {
				console.log(newValue);
			}
		);
		watch(
			() => loadingImport.value,
			(newValue: boolean, oldValue: boolean) => {
				if (!newValue && oldValue) {
					openProxy.value = false;
				}
			}
		);

		return {
			loadingImport,
			csvFile,
			openProxy,
			generateEmptyJournalCSVFile,
		};
	},
});
