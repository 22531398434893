







































import CreateUpdateOrientationDialog from "@/components/orientations/CreateUpdateOrientationDialog.vue";
import { defineComponent, onMounted, ref, toRefs, watch } from "@vue/composition-api";
import { OrientationsCRUD } from "@/composables/orientations/orientations.crud";
import BaseDatatable from "@/components/general/BaseDatatable.vue";
import Orientation from "@/models/orientations/Orientation.model";

export default defineComponent({
  components: {
    CreateUpdateOrientationDialog,
    BaseDatatable,
  },
  setup() {
    /* Import des API */
    const { orientationsData, orientationsCrud } = OrientationsCRUD();

    /* Cycle de vie */
    onMounted(() => {
      orientationsData.firstLoad = true;
      orientationsCrud.fetchOrientations();
    });

    /* Variables */
    const openDialog = ref(false);
    const createMode = ref(false);
    const updateMode = ref(false);
    const editedOrientation = ref(new Orientation());
    const headers = [
      { text: "Nom", value: "name" },
      { text: "Code CCAM", value: "code" },
      { text: "Commentaires", value: "notes" },
    ];

    /* Méthodes */
    const openDialogAsCreate = function () {
      createMode.value = true;
      openDialog.value = true;
    };
    const openDialogAsUpdate = function (orientation: Orientation) {
      updateMode.value = true;
      editedOrientation.value = orientation;
      openDialog.value = true;
    };

    /* Watchers */
    watch(
      () => orientationsData.pagination,
      () => {
        if (!orientationsData.firstLoad) {
          orientationsCrud.fetchOrientations();
        }
      },
      { deep: true }
    );
    watch(
      () => openDialog.value,
      (newValue: boolean, oldValue: boolean) => {
        if (!newValue && oldValue) {
          orientationsCrud.fetchOrientations();
        }
      }
    );

    return {
      openDialog,
      createMode,
      updateMode,
      editedOrientation,
      openDialogAsCreate,
      openDialogAsUpdate,
      ...toRefs(orientationsData),
      headers,
    };
  },
});
