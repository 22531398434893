



import { defineComponent, onMounted } from "@vue/composition-api";
import LoadingDialog from "@/components/general/LoadingDialog.vue";
import { ActivitiesCRUD } from "@/composables/activities/activities.crud";
import useXLSImportUtils from "@/composables/utils/xlsImports";
import Activite from "@/models/activite/Activite.model";

export default defineComponent({
	components: {
		LoadingDialog,
	},
	props: {
		loading: {
			type: Boolean,
			required: true,
		},
		file: {
			type: File,
			required: true,
		},
	},
	setup(props, { emit }) {
		/* APIs */
		const { activitiesData, activitiesCrud } = ActivitiesCRUD();
		const { convertCSVToJournal } = useXLSImportUtils();

		/* Cycle de vie */
		onMounted(async () => {
			const activitiesToImport = await convertCSVToJournal(props.file);
			activitiesCrud.importActivities(activitiesToImport).then((response: any) => {
				emit("import:results", response as Activite[]);
				emit("update:loading", false);
			});
		});

		return {};
	},
});
