import _axios from "@/axios";
import FacturationCriteria from "@/models/facturations/FacturationCriteria.model";
import PaginationOptions from "@/models/general/PaginationOptions.model";
import SearchInfos from "@/models/general/SearchInfos.model";
import { reactive } from "@vue/composition-api";
import { useQueryBuilder } from "../utils/queryBuilder";
import Facturation from "@/models/facturations/Facturation.model";

export function FacturationsCRUD() {
  const { buildPostCriteriaModel } = useQueryBuilder();

  const factuData = reactive({
    loading: false,
    facturations: new Array<Facturation>(),
    currentFacturation: new Facturation(),
    firstLoad: false,
    searchInfos: new SearchInfos(),
    pagination: new PaginationOptions({ itemsPerPage: 100, sortBy: ["exitTime"] }),
    criteria: new FacturationCriteria(),
    factuFormRef: {},
  });

  const factuCrud = {
    fetchFacturations: function () {
      factuData.loading = true;
      return new Promise((resolve, reject) => {
        const filtersPayload = buildPostCriteriaModel(factuData.criteria, true, factuData.pagination);
        return _axios.post(`${process.env.VUE_APP_APIURL}/facturations/search`, filtersPayload)
          .then((response: any) => {
            factuData.searchInfos.filteredTotal = response.data.filteredTotal ?? 0;
            factuData.searchInfos.overallTotal = response.data.overallTotal ?? 0;
            factuData.searchInfos.totalPages = response.data.totalPages ?? 0;
            factuData.facturations = response.data.data;
            if (factuData.firstLoad) {
              factuData.firstLoad = false;
            }
            resolve(response.data.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            factuData.loading = false;
          })
      })
    },
    getFacturationById: function (facturationId: string) {
      factuData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.get(`${process.env.VUE_APP_APIURL}/facturations/${facturationId}`)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            factuData.loading = false;
          })
      })
    },
    addFacturation: function (factu: Facturation) {
      factuData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.post(`${process.env.VUE_APP_APIURL}/facturations/create`, factu)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            factuData.loading = false;
          })
      })
    },
    updateFacturation: function (factu: Facturation) {
      factuData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.put(`${process.env.VUE_APP_APIURL}/facturations/${factu._id}`, factu)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            factuData.loading = false;
          })
      })
    },
    deleteFacturation: function (facturationId: string) {
      factuData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.delete(`${process.env.VUE_APP_APIURL}/facturations/${facturationId}`)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            factuData.loading = false;
          })
      })
    }
  };

  return {
    factuData,
    factuCrud
  }
}