























































































import { ActesCRUD } from "@/composables/actes/actes.crud";
import ActeCriteria from "@/models/actes/ActeCriteria.model";
import TypeActe from "@/models/actes/TypeActe.model";
import {
	computed,
	defineComponent,
	ref,
	toRef,
	toRefs,
	watch,
} from "@vue/composition-api";

export default defineComponent({
	props: {
		open: {
			type: Boolean,
			required: true,
		},
		criteria: {
			type: Object,
			required: true,
		},
	},
	setup(props, { emit }) {
		/* Import des APIs */
		const { actesData } = ActesCRUD();

		/* Variables */
		const selectedType = ref(new TypeActe());
		const criteriaProxy = ref(props.criteria as ActeCriteria);
		let typingTimerIdName = 0;
		let typingTimerIdCode = 0;
		const debounceInterval = 500;
		const proxyName = ref(null as string | null);
		const proxyCode = ref(null as string | null);
		const billableOptions = [
			{ value: null, text: "Tous" },
			{ value: true, text: "Oui" },
			{ value: false, text: "Non" },
		];

		/* Computed */
		const openDrawer = computed({
			get: function (): boolean {
				return props.open as boolean;
			},
			set: function (value: boolean) {
				emit("update:open", value);
			},
		});

		/* Méthodes */
		const triggerTypingTimerName = function () {
			clearTimeout(typingTimerIdName);
			typingTimerIdName = setTimeout(function () {
				criteriaProxy.value.shortName = proxyName.value;
			}, debounceInterval);
		};
		const clearTypingTimerName = function () {
			clearTimeout(typingTimerIdName);
		};
		const triggerTypingTimerCode = function () {
			clearTimeout(typingTimerIdCode);
			typingTimerIdCode = setTimeout(function () {
				criteriaProxy.value.code = proxyCode.value;
			}, debounceInterval);
		};
		const clearTypingTimerCode = function () {
			clearTimeout(typingTimerIdCode);
		};

		/* Watchers */
		watch(
			() => selectedType.value,
			(newValue: TypeActe) => {
				const tempCriteria = JSON.parse(
					JSON.stringify(criteriaProxy.value)
				) as ActeCriteria;
				if (!newValue) {
					tempCriteria.type = null;
					tempCriteria.subtype = null;
				} else if (Object.keys(newValue).length > 0) {
					tempCriteria.type = newValue.value;
				}
				criteriaProxy.value = tempCriteria;
			}
		);
		watch(
			() => criteriaProxy.value,
			(newValue: ActeCriteria) => {
				emit("update:criteria", newValue);
			},
			{ deep: true }
		);
		watch(
			() => proxyName.value,
			(newValue: string | null, oldValue: string | null) => {
				if (newValue != oldValue && !newValue) {
					criteriaProxy.value.shortName = null;
				}
			}
		);
		watch(
			() => proxyCode.value,
			(newValue: string | null, oldValue: string | null) => {
				if (newValue != oldValue && !newValue) {
					criteriaProxy.value.code = null;
				}
			}
		);

		return {
			criteriaProxy,
			openDrawer,
			typesList: toRef(actesData, "typesList"),
			selectedType,
			proxyName,
			proxyCode,
			triggerTypingTimerName,
			triggerTypingTimerCode,
			clearTypingTimerName,
			clearTypingTimerCode,
			billableOptions,
		};
	},
});
