











































































import { ref, toRefs, defineComponent } from "@vue/composition-api";
import { useLogin } from "@/composables/general/login.ts";

export default defineComponent({
	name: "Login",
	setup() {
		/* Import des API */
		const { loginData, resetPassword } = useLogin();

		/* Cycle de vie */

		/* Variables */
		const dialog = ref(true);
		const email = ref("");
		const showEmailSentMessage = ref(false);
		/* Computed */

		/* Méthodes */
		const rebootPassword = function () {
			if (email.value) {
				resetPassword(email.value).then(() => {
					showEmailSentMessage.value = true;
				});
			}
		};

		/* Watchers */

		return {
			dialog,
			email,
			rebootPassword,
			...toRefs(loginData),
			showEmailSentMessage,
		};
	},
});
