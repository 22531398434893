































































import { computed, defineComponent, onMounted, ref, toRefs } from "@vue/composition-api";
import { UhcdCRUD } from "@/composables/uhcd/uhcd.crud";
import ConfirmDialog from "@/components/general/ConfirmDialog.vue";
import UHCD from "@/models/uhcd/UHCD.model";

export default defineComponent({
  components: {
    ConfirmDialog,
  },
  props: {
    open: Boolean,
    item: {
      type: Object,
      required: true,
    },
    updateMode: Boolean,
    createMode: Boolean,
  },
  setup(props, { emit }) {
    /* Import des API */
    const { uhcdData, uhcdCrud } = UhcdCRUD();

    /* Cycle de vie */
    onMounted(() => {
      if (props.updateMode) {
        editedItem.value = JSON.parse(JSON.stringify(props.item as UHCD));
      }
    });

    /* Variables */
    const editedItem = ref(new UHCD());
    const openConfirmDialog = ref(false);

    /* Computed */
    const openProxy = computed({
      get: function () {
        return props.open;
      },
      set: function (value: boolean) {
        emit("update:open", value);
      },
    });

    /* Méthodes */
    const closeDialog = function () {
      emit("update:createMode", false);
      emit("update:updateMode", false);
      editedItem.value = new UHCD();
      openProxy.value = false;
    };
    const saveItem = function () {
      if (props.createMode) {
        uhcdCrud.addUhcd(editedItem.value).then(() => {
          closeDialog();
        });
      } else if (props.updateMode) {
        uhcdCrud.updateUhcd(editedItem.value).then(() => {
          closeDialog();
        });
      }
    };
    const deleteItem = function () {
      uhcdCrud.deleteUhcd(editedItem.value._id).then(() => {
        closeDialog();
      });
    };

    return {
      ...toRefs(uhcdData),
      openProxy,
      closeDialog,
      editedItem,
      saveItem,
      deleteItem,
      openConfirmDialog,
    };
  },
});
