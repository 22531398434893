

























































































import ActiviteCriteria from "@/models/activite/ActiviteCriteria.model";
import ActivityStatus from "@/models/enums/ActivityStatus.enum";
import TypesFacturation from "@/models/enums/TypesFacturation.enum";
import User from "@/models/user/User.model";
import store from "@/store";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
	props: {
		open: {
			type: Boolean,
			required: true,
		},
		criteria: {
			type: Object,
			required: true,
		},
	},
	setup(props, { emit }) {
		/* Variables */
		const typesFacturation = [
			{ value: null, text: "Tous" },
			{ value: TypesFacturation.FORFAIT, text: "Au forfait" },
			{ value: TypesFacturation.ACTE, text: "A l'acte" },
		];
		let dossierIntervalId = 0;
		const DEBOUNCE_TIME = 500; //ms
		const searchDossier = ref("");
		const statusList = [
			{ value: ActivityStatus.NEW, text: "Nouveau" },
			{ value: ActivityStatus.PAID, text: "Payée" },
			{ value: ActivityStatus.PARTIALLY_PAID, text: "Partiellement payée" },
			{ value: ActivityStatus.REJECTED, text: "Paiement rejeté" },
		];
		const dates = ref(new Array<string>());
		const openDatePicker = ref(false);

		/* Computed */
		const openProxy = computed({
			get: function () {
				return props.open as boolean;
			},
			set: function (value: boolean) {
				emit("update:open", value);
			},
		});
		const criteriaProxy = computed({
			get: function () {
				return props.criteria as ActiviteCriteria;
			},
			set: function (value: ActiviteCriteria) {
				emit("update:criteria", value);
			},
		});
		const datesDisplay = computed({
			get: function () {
				return dates.value
					.map((d: string) => new Date(d).toLocaleDateString())
					.join(" - ");
			},
			set: function (value: string | null) {
				if (!value) {
					dates.value = new Array<string>();
				}
			},
		});
		const userClinics = computed(function () {
			return (store.getters.loggedUser as User).clinics;
		});

		/* Méthodes */
		const clearDossierTimer = function () {
			clearTimeout(dossierIntervalId);
		};

		/* Watchers */
		watch(
			() => searchDossier.value,
			(newValue: string) => {
				clearTimeout(dossierIntervalId);
				dossierIntervalId = setTimeout(function () {
					criteriaProxy.value.dossier = newValue;
				}, DEBOUNCE_TIME);
			}
		);
		watch(
			() => dates.value,
			(newValue: string[]) => {
				const tempCriteria = JSON.parse(
					JSON.stringify(criteriaProxy.value)
				) as ActiviteCriteria;
				if (newValue.length == 0) {
					tempCriteria.dateFrom = null;
					tempCriteria.dateTo = null;
				} else if (newValue.length == 1) {
					tempCriteria.dateFrom = new Date(newValue[0]).toISOString();
					tempCriteria.dateTo = null;
				} else if (newValue.length == 2) {
					tempCriteria.dateFrom = new Date(newValue[0]).toISOString();
					tempCriteria.dateTo = new Date(newValue[1]).toISOString();
				}
				criteriaProxy.value = tempCriteria;
			}
		);

		return {
			openProxy,
			criteriaProxy,
			typesFacturation,
			searchDossier,
			clearDossierTimer,
			statusList,
			dates,
			openDatePicker,
			datesDisplay,
			userClinics,
		};
	},
});
