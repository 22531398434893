import _axios from "@/axios"
import ApiResponse from "@/models/general/ApiResponse.model";
import User from "@/models/user/User.model"
import router from "@/router";
import store from "@/store";
import { reactive } from "@vue/composition-api"


export function useLogin() {
  const loginData = reactive({
    loading: false,
    userFormRef: {},
    rules: {
      emailRules: [
        (v: string) => !!v || "L'adresse email n'est pas renseignée",
        (v: string) => validateEmail(v) || "L'adresse email n'est pas dans un format valide"
      ],
      passwordRules: [
        (v: string) => !!v || "Le mot de passe n'est pas renseigné",
        (v: string) => (!!v && v.length >= 8 && v.length < 15) || "Le mot de passe doit être compris entre 8 et 14 caractères",
      ]
    }
  });

  const validateEmail = (email: string) => {
    return new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      .test(email);
  };

  function loginUser(user: User) {
    return new Promise((resolve, reject) => {
      loginData.loading = true;
      return _axios.post(`${process.env.VUE_APP_APIURL}/auth/login`, user)
        .then((response: any) => {
          const apiResponse = response.data as ApiResponse;
          const responseUser = apiResponse.data.user as User;
          responseUser.token = apiResponse.data.token as string;
          responseUser.password = null;
          store.commit("setLoggedUser", responseUser);
          resolve(responseUser);
        })
        .catch((error: any) => {
          reject(error.data);
        })
        .finally(() => {
          loginData.loading = false;
        })
    })
  }

  function signUpUser(user: User) {
    return new Promise((resolve, reject) => {
      loginData.loading = true;
      if (loginData.userFormRef) {
        const isValid = (loginData.userFormRef as Vue & { validate: () => boolean; }).validate();
        if (!isValid) {
          loginData.loading = false;
          reject("Invalid data");
        }
      }
      return _axios.post(`${process.env.VUE_APP_APIURL}/auth/signup`, user)
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          reject(error.data);
        })
        .finally(() => {
          loginData.loading = false;
        })
    })
  }

  function resendActivationLink(email: string) {
    return new Promise((resolve, reject) => {
      loginData.loading = true;
      const isValid = validateEmail(email) && email;
      if (!isValid) {
        loginData.loading = false;
        reject("Invalid data");
      }
      return _axios.post(`${process.env.VUE_APP_APIURL}/auth/resend-activation`, email)
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          reject(error.data);
        })
        .finally(() => {
          loginData.loading = false;
        })
    })
  }

  function activateUser(requestId: string) {
    return new Promise((resolve, reject) => {
      return _axios.get(`${process.env.VUE_APP_APIURL}/auth/activate-user/${requestId}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error.data);
        })
    })
  }

  function logoutUser() {
    store.commit("setLoggedUser", new User());
    router.push({ name: "login" });
  }

  function resetPassword(email: string) {
    return new Promise((resolve, reject) => {
      loginData.loading = true;
      return _axios.post(`${process.env.VUE_APP_APIURL}/auth/reset-password`, {
        email: email
      })
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error.data);
        })
        .finally(() => {
          loginData.loading = false;
        })
    })
  }

  function setNewPassword(requestId: string, newPassword: string) {
    return new Promise((resolve, reject) => {
      loginData.loading = true;
      return _axios.post(`${process.env.VUE_APP_APIURL}/auth/new-password`, {
        requestId: requestId,
        password: newPassword
      })
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error.data);
        })
        .finally(() => {
          loginData.loading = false;
        })
    })
  }

  return {
    loginData,
    loginUser,
    signUpUser,
    resendActivationLink,
    validateEmail,
    activateUser,
    logoutUser,
    resetPassword,
    setNewPassword
  }
}