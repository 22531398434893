



import { defineComponent, onMounted } from "@vue/composition-api";
import LoadingDialog from "@/components/general/LoadingDialog.vue";
import { ActivitiesCRUD } from "@/composables/activities/activities.crud";
import useXLSImportUtils from "@/composables/utils/xlsImports";

export default defineComponent({
	components: {
		LoadingDialog,
	},
	props: {
		loading: {
			type: Boolean,
			required: true,
		},
	},
	setup(props, { emit }) {
		/* APIs */
		const { activitiesData, activitiesCrud } = ActivitiesCRUD();
		const { convertJournalToCSV } = useXLSImportUtils();

		/* Cycle de vie */
		onMounted(() => {
			activitiesCrud.fetchActivities({ paginate: false }).then(() => {
				convertJournalToCSV(activitiesData.activities);
				emit("update:loading", false);
			});
		});

		return {};
	},
});
