export default class BaseCriteria {
  public dateFrom: string | null;
  public dateTo: string | null;
  public fields: Array<{ name: string; type: string }>;

  constructor() {
    this.dateFrom = null;
    this.dateTo = null;
    this.fields = new Array<{ name: string; type: string }>();
  }

  initTodayDates() {
    const todayStart = new Date();
    const todayEnd = new Date();
    todayStart.setHours(0, 0, 0);
    todayEnd.setHours(23, 59, 59);
    this.dateFrom = new Date(todayStart).toISOString().replace(/\.\d+Z/, "Z");
    this.dateTo = new Date(todayEnd).toISOString().replace(/\.\d+Z/, "Z");
  }
}
