export default class PaginationOptions {
  public page: number;
  public itemsPerPage: number;
  public sortBy: string[];
  public sortDesc: boolean[];
  public groupBy: string[];
  public groupDesc: boolean[];
  public multiSort: boolean;
  public mustSort: boolean;
  public forcedSorts: { field: string; desc: boolean }[] | null;

  constructor(config?: any) {
    this.page = 1;
    this.itemsPerPage =
      config && config.itemsPerPage ? config.itemsPerPage : 10;
    this.sortBy = config && config.sortBy ? config.sortBy : [];
    this.sortDesc = config && config.sortDesc ? config.sortDesc : [];
    this.groupBy = [];
    this.groupDesc = [];
    this.multiSort = false;
    this.mustSort = false;
    this.forcedSorts = null;
  }
}
