import ActivityStatus from "../enums/ActivityStatus.enum";
import TypesFacturation from "../enums/TypesFacturation.enum";
import Facturation from "../facturations/Facturation.model";
import BaseObject from "../general/BaseObject.model";
import PricedAction from "../general/PricedAction.model";

export default class Activite extends BaseObject {
  public treatedAt: string;
  public dossier: string | null;
  public type: number | null;
  public billedList: PricedAction[];
  public userId: string;
  public totalBilled: number;
  public totalPaid: number | null;
  public status: string;
  public cdsId: string;
  public foundPayments: Facturation[];
  public locked: boolean;

  constructor(og?: Activite) {
    super(og);
    this.treatedAt = og && og.treatedAt ? og.treatedAt : new Date().toISOString();
    this.dossier = og && og.dossier ? og.dossier : null;
    this.type = og && og.type ? og.type : TypesFacturation.FORFAIT;
    this.userId = og && og.userId ? og.userId : "";
    this.billedList = og && og.billedList ? og.billedList : new Array<PricedAction>();
    this.totalBilled = og && og.totalBilled ? og.totalBilled : 0;
    this.totalPaid = og && og.totalPaid ? og.totalPaid : null;
    this.status = og && og.status ? og.status : ActivityStatus.NEW;
    this.cdsId = og && og.cdsId ? og.cdsId : "";
    this.foundPayments = og && og.foundPayments ? og.foundPayments : new Array<Facturation>();
    this.locked = og && og.locked != undefined ? og.locked : false;
  }
}