import BaseObject from "../general/BaseObject.model";

export default class Acte extends BaseObject {
  public name: string;
  public shortName: string;
  public code: string;
  public type: string;
  public subtype: string;
  public notes: string;
  public price: number | string | null;
  public isBillable: boolean;
  public listCims: string[];

  constructor() {
    super();
    this.name = "";
    this.shortName = "";
    this.code = "";
    this.type = "";
    this.subtype = "";
    this.notes = "";
    this.price = null;
    this.isBillable = false;
    this.listCims = new Array<string>();
  }
}