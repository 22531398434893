































































import { ref, toRefs, defineComponent, computed } from "@vue/composition-api";
import { useLogin } from "@/composables/general/login.ts";
import router from "@/router";

export default defineComponent({
	name: "Login",
	setup() {
		/* Import des API */
		const { loginData, setNewPassword } = useLogin();

		/* Cycle de vie */

		/* Variables */
		const dialog = ref(true);
		const password = ref("");
		const confirmPassword = ref("");
		const requestId = router.currentRoute.params.requestId;
		const showRedirectionCard = ref(false);

		/* Computed */
		const passwordsValid = computed(function () {
			return (
				!!password.value &&
				!!confirmPassword.value &&
				password.value == confirmPassword.value &&
				!!requestId
			);
		});

		/* Méthodes */
		const sendNewPassword = function () {
			if (password.value && requestId) {
				setNewPassword(requestId, password.value).then(() => {
					showRedirectionCard.value = true;
					setTimeout(function () {
						router.push({ name: "login" });
					}, 5000);
				});
			}
		};

		/* Watchers */

		return {
			dialog,
			password,
			confirmPassword,
			...toRefs(loginData),
			passwordsValid,
			sendNewPassword,
			showRedirectionCard,
		};
	},
});
