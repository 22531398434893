

































import { defineComponent } from "@vue/composition-api";
import ActivityStatus from "@/models/enums/ActivityStatus.enum";

export default defineComponent({
	props: {
		status: {
			type: String,
			required: true,
		},
	},
	setup() {
		return {
			ActivityStatus,
		};
	},
});
