// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = config.baseURL || config.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

import axios from "axios";
import store from "@/store";
import router from "./router";
import ApiError from "./models/general/ApiError.model";
import User from "./models/user/User.model";

const API_URL = process.env.VUE_APP_API_URL;

const configAxios = {
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
};

const _axios = axios.create(configAxios);

_axios.interceptors.request.use(
  function (config) {
    const token = (store.getters.loggedUser as User).token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(undefined, function (err) {
  return new Promise(function () {
    if (err.response.status == 401) {
      store.commit("setLoggedUser", new User());
      if (location.pathname != "/login") {
        router.push("/login");
      }
    } else {
      const newError = new ApiError();
      Object.assign(newError, err.response.data.error);
      store.commit("setApiMessage", newError);
      store.commit("setOpenApiMessage", true);
    }

    throw err;
  });
});

export default _axios;
