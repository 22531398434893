







































import CreateUpdateCotationDialog from "@/components/cotations/CreateUpdateCotationDialog.vue";
import Cotation from "@/models/cotations/Cotation.model";
import { defineComponent, onMounted, ref, toRefs, watch } from "@vue/composition-api";
import { CotationsCRUD } from "@/composables/cotations/cotations.crud";
import BaseDatatable from "@/components/general/BaseDatatable.vue";

export default defineComponent({
	components: {
		CreateUpdateCotationDialog,
		BaseDatatable,
	},
	setup() {
		/* Import des API */
		const { cotationsData, cotationsCrud } = CotationsCRUD();

		/* Cycle de vie */
		onMounted(() => {
			cotationsData.firstLoad = true;
			cotationsCrud.fetchCotations({ paginate: true });
		});

		/* Variables */
		const openDialog = ref(false);
		const createMode = ref(false);
		const updateMode = ref(false);
		const editedCotation = ref(new Cotation());
		const headers = [
			{ text: "Nom", value: "name" },
			{ text: "Prix", value: "price" },
			{ text: "Code CCAM", value: "code" },
			{ text: "Type de données", value: "type" },
			{ text: "Commentaires", value: "notes" },
		];

		/* Méthodes */
		const openDialogAsCreate = function () {
			createMode.value = true;
			openDialog.value = true;
		};
		const openDialogAsUpdate = function (cotation: Cotation) {
			updateMode.value = true;
			editedCotation.value = cotation;
			openDialog.value = true;
		};

		/* Watchers */
		watch(
			() => cotationsData.pagination,
			() => {
				if (!cotationsData.firstLoad) {
					cotationsCrud.fetchCotations({ paginate: true });
				}
			},
			{ deep: true }
		);
		watch(
			() => openDialog.value,
			(newValue: boolean, oldValue: boolean) => {
				if (!newValue && oldValue) {
					cotationsCrud.fetchCotations({ paginate: true });
				}
			}
		);

		return {
			openDialog,
			createMode,
			updateMode,
			editedCotation,
			openDialogAsCreate,
			openDialogAsUpdate,
			...toRefs(cotationsData),
			headers,
		};
	},
});
