export default class Clinic {
  public finess: string;
  public name: string;
  public isDefault: boolean;
  public id: string;
  public reversionRate: number;
  public uhcdPrice: number;
  public paysJusticeActs: boolean;

  constructor(og?: Clinic) {
    this.finess = og && og.finess ? og.finess : "";
    this.name = og && og.name ? og.name : "";
    this.isDefault = og && og.isDefault != undefined ? og.isDefault : false;
    this.id = og && og.id ? og.id : "";
    this.reversionRate = og && og.reversionRate != undefined ? og.reversionRate : 0;
    this.uhcdPrice = og && og.uhcdPrice != undefined ? og.uhcdPrice : 0;
    this.paysJusticeActs = og && og.paysJusticeActs != undefined ? og.paysJusticeActs : false;
  }
}