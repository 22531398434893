
































import {
	computed,
	defineComponent,
	onMounted,
	onUpdated,
	ref,
} from "@vue/composition-api";

export default defineComponent({
	props: {
		hasActions: Boolean,
		elevation: {
			type: Number,
			default: 1,
		},
		extended: Boolean,
		color: {
			type: String,
			default: "white",
		},
		dark: Boolean,
		centerMainContent: Boolean,
	},
	setup(props, { root }) {
		/* Cycle de vie */
		onMounted(() => {
			/*computeActionsOverlap();
      window.addEventListener("resize", function () {
        computeActionsOverlap();
      });*/
		});

		/* Variables */
		const showCollapsedButtons = ref(false);

		/* Computed */
		const isMobile = computed(function () {
			return root.$vuetify.breakpoint.xs;
		});

		/* Méthodes */
		const computeActionsOverlap = function () {
			const globalContainer = document.querySelector("#resume-container") as HTMLElement;
			const titleContainer = document.querySelector("#resume-title") as HTMLElement;
			const actionsContainer = document.querySelector("#resume-actions") as HTMLElement;
			if (titleContainer && actionsContainer && globalContainer) {
				const totalContainerWidth = globalContainer.clientWidth;
				const titleArea = titleContainer.scrollHeight * titleContainer.scrollWidth;
				const actionsArea = actionsContainer.scrollHeight * actionsContainer.scrollWidth;
				showCollapsedButtons.value = isMobile.value && actionsArea > titleArea;
			}
		};

		return {
			isMobile,
			showCollapsedButtons,
			computeActionsOverlap,
		};
	},
});
