




















































import { defineComponent, onMounted, toRefs, watch } from "@vue/composition-api";
import { UsersCRUD } from "@/composables/user/users.crud";
import BaseDatatable from "@/components/general/BaseDatatable.vue";

export default defineComponent({
  components: {
    BaseDatatable,
  },
  setup() {
    /* Import des API */
    const { usersData, userCrud } = UsersCRUD();

    /* Cycle de vie */
    onMounted(() => {
      userCrud.fetchUsers();
    });

    /* Variables */
    const headers = [
      { text: "Nom", value: "username" },
      { text: "Email", value: "email" },
      { text: "Activé", value: "isActivated" },
      { text: "RPPS", value: "rpps" },
      { text: "Droits", value: "rights" },
      { text: "CDS", value: "clinics" },
    ];

    /* Watchers */
    watch(
      () => usersData.pagination,
      () => {
        userCrud.fetchUsers();
      },
      { deep: true }
    );

    return {
      ...toRefs(usersData),
      headers,
    };
  },
});
