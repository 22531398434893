export default class SearchInfos {
  public filteredTotal: number;
  public totalPages: number;
  public overallTotal: number;

  constructor() {
    this.filteredTotal = 0;
    this.totalPages = 0;
    this.overallTotal = 0;
  }
}
