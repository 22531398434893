












































































import Clinic from "@/models/clinics/Clinic.model";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { v4 as uuidv4 } from "uuid";

export default defineComponent({
	props: {
		open: Boolean,
		item: {
			type: Object,
			required: true,
		},
		updateMode: Boolean,
		createMode: Boolean,
	},
	setup(props, { emit }) {
		/* Cycle de vie */
		onMounted(() => {
			if (props.updateMode) {
				editedItem.value = JSON.parse(JSON.stringify(props.item as Clinic));
			}
		});

		/* Variables */
		const editedItem = ref(new Clinic());

		/* Computed */
		const openProxy = computed({
			get: function () {
				return props.open;
			},
			set: function (value: boolean) {
				emit("update:open", value);
			},
		});

		/* Méthodes */
		const closeDialog = function () {
			emit("update:createMode", false);
			emit("update:updateMode", false);
			editedItem.value = new Clinic();
			openProxy.value = false;
		};
		const saveItem = function () {
			if (props.createMode) {
				editedItem.value.id = uuidv4();
			}
			emit("save:clinic", editedItem.value);
			closeDialog();
		};

		return {
			openProxy,
			closeDialog,
			editedItem,
			saveItem,
		};
	},
});
