import _axios from "@/axios";
import RequestConfig from "@/interfaces/RequestConfig.interface";
import Activite from "@/models/activite/Activite.model";
import ActiviteCriteria from "@/models/activite/ActiviteCriteria.model";
import PaginationOptions from "@/models/general/PaginationOptions.model";
import SearchInfos from "@/models/general/SearchInfos.model";
import { reactive } from "@vue/composition-api";
import { useQueryBuilder } from "../utils/queryBuilder";

export function ActivitiesCRUD() {
  const { buildPostCriteriaModel } = useQueryBuilder();

  const activitiesData = reactive({
    loading: false,
    activities: new Array<Activite>(),
    currentActivity: new Activite(),
    firstLoad: false,
    searchInfos: new SearchInfos(),
    pagination: new PaginationOptions({ itemsPerPage: 100, sortBy: ["treatedAt"], sortDesc: [true] }),
    criteria: new ActiviteCriteria(),
    activitiesFormRef: {},
  });

  const activitiesCrud = {
    fetchActivities: function (requestConfig: RequestConfig) {
      activitiesData.loading = true;
      return new Promise((resolve, reject) => {
        const filtersPayload = buildPostCriteriaModel(activitiesData.criteria, true, activitiesData.pagination);
        return _axios.post(`${process.env.VUE_APP_APIURL}/activities/search`, filtersPayload)
          .then((response: any) => {
            activitiesData.searchInfos.filteredTotal = response.data.filteredTotal ?? 0;
            activitiesData.searchInfos.overallTotal = response.data.overallTotal ?? 0;
            activitiesData.searchInfos.totalPages = response.data.totalPages ?? 0;
            activitiesData.activities = response.data.data;
            if (activitiesData.firstLoad) {
              activitiesData.firstLoad = false;
            }
            resolve(response.data.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            activitiesData.loading = false;
          })
      })
    },
    getActivityById: function (activityId: string) {
      activitiesData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.get(`${process.env.VUE_APP_APIURL}/activities/${activityId}`)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            activitiesData.loading = false;
          })
      })
    },
    addActivity: function (activity: Activite) {
      activitiesData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.post(`${process.env.VUE_APP_APIURL}/activities/create`, activity)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            activitiesData.loading = false;
          })
      })
    },
    importActivities: function (activities: Activite[]) {
      return new Promise((resolve, reject) => {
        return _axios.post(`${process.env.VUE_APP_APIURL}/activities/import`, activities)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
      })
    },
    updateActivity: function (activity: Activite) {
      activitiesData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.put(`${process.env.VUE_APP_APIURL}/activities/${activity._id}`, activity)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            activitiesData.loading = false;
          })
      })
    },
    deleteActivity: function (activityId: string) {
      activitiesData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.delete(`${process.env.VUE_APP_APIURL}/activities/${activityId}`)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            activitiesData.loading = false;
          })
      })
    },
    lockActivity: function (activityId: string, lock: boolean) {
      activitiesData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.patch(`${process.env.VUE_APP_APIURL}/activities/${activityId}`, {
          locked: lock
        })
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            activitiesData.loading = false;
          })
      })
    },
  };

  return {
    activitiesData,
    activitiesCrud
  }
}