import User from "@/models/user/User.model"
import store from "@/store"

export default function useRightsUtils() {
  function hasRight(rightToCheck: string) {
    const loggedUser = store.getters.loggedUser as User;
    return loggedUser.rights.indexOf(rightToCheck) > -1;
  }

  return {
    hasRight
  }
}