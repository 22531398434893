import Clinic from "../clinics/Clinic.model";
import Transportation from "./Transportation.model";

export default class User {
  public _id: string | null;
  public username: string;
  public email: string;
  public password: string | null;
  public rights: string[];
  public clinics: Clinic[];
  public token: string;
  public rpps: string;
  public isActivated: boolean;
  public emailConfirmed: boolean;
  public transportations: Transportation[];

  constructor() {
    this._id = null;
    this.username = "";
    this.email = "";
    this.rights = new Array<string>();
    this.clinics = new Array<Clinic>();
    this.token = "";
    this.password = null;
    this.rpps = "";
    this.isActivated = false;
    this.emailConfirmed = false;
    this.transportations = new Array<Transportation>();
  }
}