import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import "./axios";
import VueCompositionApi from "@vue/composition-api";

import "./assets/css/app.dev.css";
import '@mdi/font/css/materialdesignicons.css'

Vue.use(VueCompositionApi);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
