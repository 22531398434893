export class ApiCondition {
  public dateFrom: string | null;
  public dateTo: string | null;
  public filter: string | null;
  public bool: boolean | null;
  public filterType: string;
  public type: string;
  public values: Array<string> | null;

  constructor() {
    this.dateFrom = null;
    this.dateTo = null;
    this.filter = "";
    this.filterType = "";
    this.type = "";
    this.values = null;
    this.bool = null;
  }
}
