import BaseObject from "../general/BaseObject.model";

export default class Orientation extends BaseObject{
  public code: string;
  public notes: string;
  public name: string;

  constructor() {
    super();
    this.code = "";
    this.notes = "";
    this.name = "";
  }
}