































import ApiError from "@/models/general/ApiError.model";
import store from "@/store";
import { computed, defineComponent } from "@vue/composition-api";
import ConfirmDialog from "@/components/general/ConfirmDialog.vue";
import ApiErrorTypes from "@/models/enums/ApiErrorTypes.model";

export default defineComponent({
	components: {
		ConfirmDialog,
	},
	props: {
		open: Boolean,
	},
	setup(props, { emit }) {
		/* Computed */
		const openProxy = computed({
			get: function () {
				return props.open;
			},
			set: function (value: boolean) {
				emit("update:open", value);
			},
		});
		const apiMessage = computed(function () {
			return store.getters.apiMessage as ApiError;
		});

		return {
			openProxy,
			apiMessage,
			ApiErrorTypes,
		};
	},
});
