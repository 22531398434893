import _axios from "@/axios";
import RequestConfig from "@/interfaces/RequestConfig.interface";
import Acte from "@/models/actes/Acte.model";
import ActeCriteria from "@/models/actes/ActeCriteria.model";
import ActeSubtypes from "@/models/enums/ActesSubtypes.enum";
import ActeTypes from "@/models/enums/ActeTypes.enum";
import PaginationOptions from "@/models/general/PaginationOptions.model";
import SearchInfos from "@/models/general/SearchInfos.model";
import { reactive } from "@vue/composition-api";
import { useQueryBuilder } from "../utils/queryBuilder";

export function ActesCRUD() {
  const { buildPostCriteriaModel } = useQueryBuilder();

  const actesData = reactive({
    loading: false,
    actes: new Array<Acte>(),
    currentActe: new Acte(),
    firstLoad: false,
    searchInfos: new SearchInfos(),
    pagination: new PaginationOptions({ itemsPerPage: 100, sortBy: ["type"] }),
    criteria: new ActeCriteria(),
    actesFormRef: {},
    typesList: [
      {
        value: ActeTypes.BRULURES, text: "Brûlures", subtypes: [
          { value: ActeSubtypes.PANSEMENT_INITIAL, text: "Pansement initial" },
          { value: ActeSubtypes.PANSEMENT_SECONDAIRE, text: "Pansement secondaire" },
        ]
      },
      { value: ActeTypes.CONSULTATION, text: "Consultations", subtypes: [] },
      { value: ActeTypes.CORPS_ETRANGER, text: "Corps étranger - Ablation", subtypes: [] },
      { value: ActeTypes.ECHOGRAPHIE, text: "Echographie", subtypes: [] },
      { value: ActeTypes.JUSTICE, text: "Frais de justice", subtypes: [] },
      { value: ActeTypes.GASTRO, text: "Gastrologie / Proctologie", subtypes: [] },
      {
        value: ActeTypes.IMMOBILISATIONS, text: "Immobilisations", subtypes: [
          { value: ActeSubtypes.APPRIG_AVFRACTURE, text: "Appareils rigides avec fracture" },
          { value: ActeSubtypes.APPRIG_SSFRACTURE, text: "Appareils rigides sans fracture" },
          { value: ActeSubtypes.STRAPPING, text: "Contentions souples / Strapping" },
          { value: ActeSubtypes.ATTELLE_POSTURE_NNAMOVIBLE, text: "Attelle de posture (non-amovible)" },
          { value: ActeSubtypes.ATTELLE_POSTURE_AMOVIBLE, text: "Confection orthèse rigide (amovible)" },
        ]
      },
      { value: ActeTypes.INFILT, text: "Infiltration", subtypes: [] },
      { value: ActeTypes.LUXATIONS, text: "Luxations", subtypes: [] },
      { value: ActeTypes.OPHTALMO, text: "Ophtalmologie", subtypes: [] },
      { value: ActeTypes.ORL, text: "ORL", subtypes: [] },
      {
        value: ActeTypes.PLAIES, text: "Plaies", subtypes: [
          { value: ActeSubtypes.PLAIE_TETE, text: "Plaies tête" },
          { value: ActeSubtypes.PLAIE_FACE, text: "Plaies face" },
          { value: ActeSubtypes.PLAIE_MAIN, text: "Plaies main" },
          { value: ActeSubtypes.PLAIE_HFACE, text: "Plaies hors face" },
          { value: ActeSubtypes.PLAIE_VEINE, text: "Plaies veine" },
          { value: ActeSubtypes.PLAIE_TENDON, text: "Plaies tendons" },
          { value: ActeSubtypes.PLAIE_GYNECO, text: "Plaies gynéco" },
          { value: ActeSubtypes.TRAUMA_ONGLES, text: "Traumatismes des ongles" },
        ]
      },
      { value: ActeTypes.ABCES_PONCTIONS, text: "Ponctions, Evacuations, Drainages", subtypes: [] },
      { value: ActeTypes.REA, text: "Réanimation / Etat général", subtypes: [] },
      { value: ActeTypes.REDUCTIONS, text: "Réductions", subtypes: [] },
      { value: ActeTypes.URO, text: "Urologie", subtypes: [] },
    ]
  });

  const actesCrud = {
    fetchActes: function (requestConfig: RequestConfig) {
      actesData.loading = true;
      return new Promise((resolve, reject) => {
        const filtersPayload = buildPostCriteriaModel(actesData.criteria, requestConfig.paginate, actesData.pagination);
        return _axios.post(`${process.env.VUE_APP_APIURL}/actes/search`, filtersPayload)
          .then((response: any) => {
            actesData.searchInfos.filteredTotal = response.data.filteredTotal;
            actesData.searchInfos.overallTotal = response.data.overallTotal;
            actesData.searchInfos.totalPages = response.data.totalPages;
            actesData.actes = response.data.data;
            if (actesData.firstLoad) {
              actesData.firstLoad = false;
            }
            resolve(response.data.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            actesData.loading = false;
          })
      })
    },
    getActeById: function (acteId: string) {
      actesData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.get(`${process.env.VUE_APP_APIURL}/actes/${acteId}`)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            actesData.loading = false;
          })
      })
    },
    addActe: function (acte: Acte) {
      actesData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.post(`${process.env.VUE_APP_APIURL}/actes/create`, acte)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            actesData.loading = false;
          })
      })
    },
    updateActe: function (acte: Acte) {
      actesData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.put(`${process.env.VUE_APP_APIURL}/actes/${acte._id}`, acte)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            actesData.loading = false;
          })
      })
    },
    deleteActe: function (acteId: string) {
      actesData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.delete(`${process.env.VUE_APP_APIURL}/actes/${acteId}`)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            actesData.loading = false;
          })
      })
    }
  };

  return {
    actesData,
    actesCrud
  }
}