import _axios from "@/axios";
import Admission from "@/models/admissions/Admission.model";
import AdmissionCriteria from "@/models/admissions/AdmissionCriteria.model";
import PaginationOptions from "@/models/general/PaginationOptions.model";
import SearchInfos from "@/models/general/SearchInfos.model";
import { reactive } from "@vue/composition-api";
import { useQueryBuilder } from "../utils/queryBuilder";

export function AdmissionsCRUD() {
  const { buildPostCriteriaModel } = useQueryBuilder();

  const admissionsData = reactive({
    loading: false,
    admissions: new Array<Admission>(),
    currentAdmission: new Admission(),
    firstLoad: false,
    searchInfos: new SearchInfos(),
    pagination: new PaginationOptions(),
    criteria: new AdmissionCriteria(),
  });

  const admissionsCrud = {
    fetchAdmissions: function () {
      admissionsData.loading = true;
      return new Promise((resolve, reject) => {
        const filtersPayload = buildPostCriteriaModel(admissionsData.criteria, true, admissionsData.pagination);
        return _axios.post(`${process.env.VUE_APP_APIURL}/admissions/search`, filtersPayload)
          .then((response: any) => {
            admissionsData.searchInfos.filteredTotal = response.data.filteredTotal;
            admissionsData.searchInfos.overallTotal = response.data.overallTotal;
            admissionsData.searchInfos.totalPages = response.data.totalPages;
            admissionsData.admissions = response.data.data;
            if (admissionsData.firstLoad) {
              admissionsData.firstLoad = false;
            }
            resolve(response.data.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            admissionsData.loading = false;
          })
      })
    },
    getAdmissionById: function (admissionId: string) {
      admissionsData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.get(`${process.env.VUE_APP_APIURL}/admissions/${admissionId}`)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            admissionsData.loading = false;
          })
      })
    },
    addAdmission: function (admission: Admission) {
      admissionsData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.post(`${process.env.VUE_APP_APIURL}/admissions/create`, admission)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            admissionsData.loading = false;
          })
      })
    },
    updateAdmission: function (admission: Admission) {
      admissionsData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.put(`${process.env.VUE_APP_APIURL}/admissions/${admission._id}`, admission)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            admissionsData.loading = false;
          })
      })
    },
    deleteAdmission: function (admissionId: string) {
      admissionsData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.delete(`${process.env.VUE_APP_APIURL}/admissions/${admissionId}`)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            admissionsData.loading = false;
          })
      })
    }
  };

  return {
    admissionsData,
    admissionsCrud
  }
}