var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-2"},[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-multiple")]),_vm._v(" Utilisateurs enregistrés ")],1),_c('v-divider'),_c('v-card-text',{attrs:{"id":"users-table"}},[_c('base-datatable',{staticClass:"table-clickable",attrs:{"headers":_vm.headers,"fixedHeaders":true,"items":_vm.users,"containerId":"#users-table","clickOnRow":true,"options":_vm.pagination,"serverItemsLength":_vm.searchInfos.overallTotal,"itemClickRouterTarget":"user-view","loading":_vm.loading},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.isActivated",fn:function(ref){
var item = ref.item;
return [(item.isActivated)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])]}},{key:"item.rights",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.rights.join(", "))+" ")]}},{key:"item.clinics",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-medical-bag")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((item.clinics),function(clinic,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(clinic.name))]),_c('v-list-item-subtitle',[_vm._v(" FINESS : "+_vm._s(clinic.finess)+" ")])],1)],1)}),1)],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }