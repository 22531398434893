import Activite from "@/models/activite/Activite.model";
import * as XLSX from "xlsx"
import * as FileSaver from "file-saver"
import TypesFacturation from "@/models/enums/TypesFacturation.enum";
import PricedAction from "@/models/general/PricedAction.model";
import store from "@/store";
import User from "@/models/user/User.model";
import Clinic from "@/models/clinics/Clinic.model";
import { ActesCRUD } from "../actes/actes.crud";
import { CotationsCRUD } from "../cotations/cotations.crud";
import Cotation from "@/models/cotations/Cotation.model";
import Acte from "@/models/actes/Acte.model";

export default function useXLSImportUtils() {
  /* APIs */
  const { actesCrud, actesData } = ActesCRUD();
  const { cotationsCrud, cotationsData } = CotationsCRUD();


  function extractActsFromSocialSecurityActivity(file: File) {
    // On ouvre le fichier XLS/X

    // On récupère le numéro du lot

    // On récupère la date de paiement du lot

    // On récupère le total facturé du lot

    // On cherche les numéros de factures
    // Elles se trouvent toutes dans la colonnes B
    // On vérifie si le contenu de la cellule est soit un nombre à 9 chiffres, soit contient SNF, soit contient SNC

    // Pour chaque numéro de facture
    // On récupère le numéro total du lot dans la colonne N[ligne facture]
    // On récupère le numéro de SS associé à la facture dans les colonnes C&D[ligne facture - 1] (fusionnées)
    // On récupère le texte nom/prénom/date naissance du patient dans les colonnes E:L[ligne facture - 1]
    // On récupère le détail des actes du dossier :
    //  On se place sur la ligne facture +1
    //  On boucle sur les ligns des colonnes C&D qui ne sont pas vides (libellé de l'acte) jusqu'à trouver une ligne de C&D qui soit vide
    //  Pour chaque ligne trouvée, on récupère :
    //  - L'acte dans les colonnes C&D
    //  - La date de paiement dans les colonnes E&F
    //  - La quantité dans la colonne G
    //  - Le montant Dépense dans les colonnes H&I
    //  - La montant RO dans les colonnes J:L
    //  - La montant RC dans la colonne M
  }

  function convertJournalToCSV(journal: Activite[]) {
    const exportJournal = journal.map((activity: Activite) => {
      let billingType = "";
      if (activity.type == TypesFacturation.FORFAIT) {
        billingType = "Forfait";
      } else if (activity.type == TypesFacturation.ACTE) {
        billingType = "Acte";
      }
      const loggedUser = (store.getters.loggedUser as User);
      const cds = loggedUser.clinics.filter((c: Clinic) => c.id == activity.cdsId)[0];
      let timeWithoutSecondsArray = new Date(activity.treatedAt).toLocaleTimeString().split(":");
      timeWithoutSecondsArray.pop();
      const timeWithoutSeconds = timeWithoutSecondsArray.join(":");
      return {
        date: new Date(activity.treatedAt).toLocaleDateString(),
        time: timeWithoutSeconds,
        dossier: activity.dossier,
        type: billingType,
        actions: activity.billedList.map((b: PricedAction) => b.code).join(","),
        totalBilled: activity.totalBilled,
        cds: cds ? cds.name : ""
      };
    });
    const journalSheet = XLSX.utils.json_to_sheet(exportJournal);
    // Renommage des headers
    XLSX.utils.sheet_add_aoa(
      journalSheet,
      [
        [
          "Date",
          "Heure",
          "N° de dossier",
          "Facturation",
          "Actes/Forfaits",
          "Facturé",
          "Centre de santé"
        ]
      ],
      { origin: "A1" }
    );
    // Conversion en CSV
    let csv = XLSX.utils.sheet_to_csv(journalSheet, { FS: ";" });
    csv = "\uFEFF" + csv;
    const fileType = "text/csv;charset=UTF-8";
    const exportBlob = new Blob([csv], { type: fileType });
    FileSaver.saveAs(
      exportBlob,
      `Journal_${new Date().toLocaleDateString().replace(/\//g, "-")}.csv`
    );
  }

  async function convertCSVToJournal(csv: File) {
    const result = new Array<Activite>();
    if (csv.type == "text/csv") {
      await actesCrud.fetchActes({ paginate: false });
      await cotationsCrud.fetchCotations({ paginate: false });
      const csvText = await csv.text();
      const rows = csvText.split('\n');
      const aoa = rows.map(e => e.split(";"));
      console.log(aoa);
      aoa.forEach((array: string[], index: number) => {
        if (index > 0) {
          const newActivity = new Activite();
          const dateString = array[0];
          const timeString = array[1];
          const dossier = array[2];
          const type = array[3];
          const actions = array[4];
          const totalBilled = array[5];
          const cds = array[6];

          // On recontruit la date pour la mettre au format ISO
          const dateArray = dateString.split("/").reverse();
          const internationalDateString = dateArray.join("-");
          const activityDate = new Date(internationalDateString);
          const timeArray = timeString.split(":");
          activityDate.setHours(parseInt(timeArray[0]));
          activityDate.setMinutes(parseInt(timeArray[1]));
          newActivity.treatedAt = activityDate.toISOString();

          // On assigne le numéro de dossier
          newActivity.dossier = dossier;

          // On assigne le type de facturation
          if (type.toLowerCase().indexOf('forfait') > -1) {
            newActivity.type = TypesFacturation.FORFAIT;
          } else if (type.toLowerCase().indexOf('acte') > -1) {
            newActivity.type = TypesFacturation.ACTE;
          }

          // On assigne le total facturé (safeguard de conversion des caractères décimaux)
          newActivity.totalBilled = parseFloat(totalBilled.replace(",", "."));

          // On va retrouver le CDS grâce à son nom pour en extraire son ID
          const loggedUser = (store.getters.loggedUser as User);
          const userClinics = loggedUser.clinics;
          const normalizedCdsName = cds.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          const foundCds = userClinics.filter((c: Clinic) => c.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() == normalizedCdsName.toLowerCase())[0];
          if (foundCds) {
            newActivity.cdsId = foundCds.id;
          }

          // On assigne le propriétaire
          newActivity.userId = loggedUser._id as string;

          // On va chercher les actes et les forfaits correspondants aux codes renseignés
          const actionsArray = actions.split(",").map((e: string) => e.trim());
          if (newActivity.type == TypesFacturation.FORFAIT) {
            actionsArray.forEach((code: string) => {
              const forfait = cotationsData.cotations.filter((f: Cotation) => f.code == code)[0];
              if (forfait) {
                newActivity.billedList.push(new PricedAction(forfait));
              }
            });
          } else if (newActivity.type == TypesFacturation.ACTE) {
            actionsArray.forEach((code: string) => {
              const acte = actesData.actes.filter((a: Acte) => a.code == code)[0];
              if (acte) {
                newActivity.billedList.push(new PricedAction(acte));
              }
            });
          }

          // On ajoute l'activité à la liste
          result.push(newActivity);
        }
      });
    }

    return result;
  }

  function generateEmptyJournalCSVFile() {
    const emptySheet = new Array<Activite>();
    XLSX.utils.sheet_add_aoa(
      emptySheet,
      [
        [
          "Date",
          "Heure",
          "N° de dossier",
          "Facturation",
          "Actes/Forfaits",
          "Facturé",
          "Centre de santé"
        ]
      ],
      { origin: "A1" }
    );
    // Conversion en CSV
    let csv = XLSX.utils.sheet_to_csv(emptySheet, { FS: ";" });
    csv = "\uFEFF" + csv;
    const fileType = "text/csv;charset=UTF-8";
    const exportBlob = new Blob([csv], { type: fileType });
    FileSaver.saveAs(
      exportBlob,
      `Exemple_Journal.csv`
    );
  }

  return {
    convertJournalToCSV,
    convertCSVToJournal,
    generateEmptyJournalCSVFile
  }
}