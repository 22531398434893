







































import { computed, defineComponent, onMounted, ref, toRefs } from "@vue/composition-api";
import { FacturationsCRUD } from "@/composables/facturations/facturations.crud";
import BaseDatatable from "@/components/general/BaseDatatable.vue";
import Facturation from "@/models/facturations/Facturation.model";

export default defineComponent({
	components: {
		BaseDatatable,
	},
	setup(props, { root }) {
		/* Import des APIs */
		const { factuData, factuCrud } = FacturationsCRUD();

		/* Cycle de vie */
		onMounted(() => {
			factuCrud.fetchFacturations();
		});

		/* Variables */
		const headers = [
			{ text: "N° Passage", value: "ipp", divider: true },
			{ text: "Age", value: "age" },
			{ text: "Arrivée le", value: "arrivalTime" },
			{ text: "Prise en charge", value: "careTime", divider: true },
			{ text: "Sortie le", value: "exitTime" },
			{ text: "Orientation", value: "exitDestination", divider: true },
			{ text: "Type facturation", value: "type" },
			{ text: "Actes", value: "actes" },
			{ text: "Forfaits", value: "forfaits" },
			{ text: "Prix facturé", value: "totalPrice", divider: true },
			{ text: "Actions", value: "", sortable: false },
		];
		const openDialog = ref(false);
		const createMode = ref(false);
		const updateMode = ref(false);
		const editedFacturation = ref(new Facturation());

		/* Computed */
		const isMobile = computed(function () {
			return root.$vuetify.breakpoint.xs;
		});

		/* Méthodes */
		const openDialogAsCreate = function () {
			createMode.value = true;
			openDialog.value = true;
		};
		const openDialogAsUpdate = function (facturation: Facturation) {
			updateMode.value = true;
			editedFacturation.value = facturation;
			openDialog.value = true;
		};

		return {
			...toRefs(factuData),
			isMobile,
			headers,
			openDialog,
			createMode,
			updateMode,
			editedFacturation,
			openDialogAsCreate,
			openDialogAsUpdate,
		};
	},
});
