








































































































































































































































import {
	computed,
	defineComponent,
	onMounted,
	onUnmounted,
	ref,
	toRefs,
	watch,
} from "@vue/composition-api";
import BaseDatatable from "@/components/general/BaseDatatable.vue";
import Activite from "@/models/activite/Activite.model";
import CreateUpdateActivityDialog from "@/components/activity/CreateUpdateActivityDialog.vue";
import { ActivitiesCRUD } from "@/composables/activities/activities.crud";
import { useDateUtils } from "@/composables/utils/dates";
import ActivityFacturationType from "@/components/activity/ActivityFacturationType.vue";
import ActivityStatusChip from "@/components/activity/ActivityStatusChip.vue";
import ResumeToolbar from "@/components/general/ResumeToolbar.vue";
import ActivityFilters from "@/components/activity/ActivityFilters.vue";
import store from "@/store";
import User from "@/models/user/User.model";
import useTitleUtils from "@/composables/utils/title";
import ActivityDownloader from "@/components/activity/ActivityDownloader.vue";
import ActivityImportDialog from "@/components/activity/ActivityImportDialog.vue";

export default defineComponent({
	components: {
		BaseDatatable,
		CreateUpdateActivityDialog,
		ActivityFacturationType,
		ActivityStatusChip,
		ResumeToolbar,
		ActivityFilters,
		ActivityDownloader,
		ActivityImportDialog,
	},
	setup(props, { root }) {
		/* Import des APIs */
		const { activitiesData, activitiesCrud } = ActivitiesCRUD();
		const { formatDateTimeToString } = useDateUtils();
		const { setTitle, setIcon } = useTitleUtils();

		/* Cycle de vie */
		onMounted(() => {
			setTitle("Mon activité");
			setIcon("mdi-book-open-variant");
			activitiesData.firstLoad = true;
			activitiesData.criteria.userId = loggedUser.value._id;
			applyFilters();
		});
		onUnmounted(() => {
			setTitle("");
			setIcon("");
		});

		/* Variables */
		const headers = [
			{ text: "N° Dossier", value: "dossier" },
			{ text: "Traité le", value: "treatedAt", divider: true },
			{ text: "Type facturation", value: "type" },
			{
				text: "Actes/Forfaits facturés",
				value: "billedList",
				sortable: false,
				divider: true,
			},
			{ text: "Total facturé", value: "totalBilled" },
			{ text: "Statut", value: "status" },
			{ text: "Total payé", value: "totalPaid" },
		];
		const openDialog = ref(false);
		const editedActivity = ref(new Activite());
		const openFilters = ref(false);
		const loadingDownload = ref(false);
		const importDialog = ref(false);

		/* Computed */
		const isMobile = computed(function () {
			return root.$vuetify.breakpoint.xs;
		});
		const loggedUser = computed(function () {
			return store.getters.loggedUser as User;
		});

		/* Méthodes */
		const openActivity = function (activity: Activite) {
			editedActivity.value = activity;
			openDialog.value = true;
		};
		const applyFilters = function () {
			activitiesCrud.fetchActivities({ paginate: true });
		};

		/* Watchers */
		watch(
			() => openDialog.value,
			(newValue: boolean, oldValue: boolean) => {
				if (!newValue && oldValue) {
					editedActivity.value = new Activite();
					applyFilters();
				}
			}
		);
		watch(
			() => activitiesData.criteria,
			() => {
				if (!activitiesData.firstLoad) {
					applyFilters();
				}
			},
			{ deep: true }
		);
		watch(
			() => importDialog.value,
			(newValue: boolean, oldValue: boolean) => {
				if (!newValue && oldValue) {
					applyFilters();
				}
			}
		);
		watch(
			() => activitiesData.pagination,
			() => {
				if (!activitiesData.firstLoad) {
					applyFilters();
				}
			},
			{ deep: true }
		);

		return {
			...toRefs(activitiesData),
			isMobile,
			headers,
			openDialog,
			editedActivity,
			openActivity,
			formatDateTimeToString,
			applyFilters,
			openFilters,
			loadingDownload,
			importDialog,
		};
	},
});
