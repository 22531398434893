import store from "@/store";

export default function useTitleUtils() {
  function setTitle(title: string) {
    store.commit("setTitle", title);
  }

  function setIcon(icon: string) {
    store.commit("setIcon", icon);
  }

  return {
    setTitle,
    setIcon
  }
}