export function useDateUtils() {
  // Renvoie la date correspondante au jour donné dans la semaine courante (format lundi-dimanche)
  function getCurrentWeekDay(date: Date) {
    const now = new Date(Date.now());
    const currentDay = now.getDay();
    let currentWeek = [] as Date[];
    let target = null;
    const day = date.getDay();
    if (currentDay > 0) {
      let newNow = new Date(now);
      const monday = new Date(newNow.setDate(now.getDate() - (currentDay - 1)));
      currentWeek.push(monday);
      for (let i = 0; i < 6; i++) {
        const nextDay = new Date(monday).setDate(monday.getDate() + i + 1);
        currentWeek.push(new Date(nextDay));
      }
    } else {
      // Si on est dimanche
      let newNow = new Date(now);
      const sunday = new Date(newNow);
      currentWeek.push(sunday);
      for (let i = 0; i < 6; i++) {
        const prevDay = new Date(sunday).setDate(now.getDate() - i - 1);
        currentWeek.splice(0, 0, new Date(prevDay));
      }
    }

    if (day > 0) {
      target = currentWeek[day - 1];
    } else {
      target = currentWeek[currentWeek.length - 1];
    }
    const daysSpan = Math.floor(
      (target.getTime() - date.getTime()) / 1000 / 60 / 60 / 24
    );
    date.setDate(date.getDate() - daysSpan);
  }

  function formatDateTimeToString(
    dateIsoString: string,
    withSeconds: boolean = false
  ) {
    const localDateTime = new Date(dateIsoString).toLocaleString();
    if (localDateTime != "Invalid Date") {
      const dateTab = localDateTime.split(" ");
      const datePart = dateTab[0].trim();
      let timePart = dateTab[1].trim();
      let timePartTab = timePart.split(":");
      if (!withSeconds) {
        timePartTab.splice(2, 1);
      }
      timePart = timePartTab.join(":");

      return `${datePart} à ${timePart}`;
    }
  }

  return {
    getCurrentWeekDay,
    formatDateTimeToString,
  };
}
