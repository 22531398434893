







































import CreateUpdateUhcdDialog from "@/components/uhcd/CreateUpdateUhcdDialog.vue";
import { defineComponent, onMounted, ref, toRefs, watch } from "@vue/composition-api";
import { UhcdCRUD } from "@/composables/uhcd/uhcd.crud";
import BaseDatatable from "@/components/general/BaseDatatable.vue";
import UHCD from "@/models/uhcd/UHCD.model";

export default defineComponent({
  components: {
    CreateUpdateUhcdDialog,
    BaseDatatable,
  },
  setup() {
    /* Import des API */
    const { uhcdData, uhcdCrud } = UhcdCRUD();

    /* Cycle de vie */
    onMounted(() => {
      uhcdData.firstLoad = true;
      uhcdCrud.fetchUhcds();
    });

    /* Variables */
    const openDialog = ref(false);
    const createMode = ref(false);
    const updateMode = ref(false);
    const editedUhcd = ref(new UHCD());
    const headers = [
      { text: "Nom", value: "name" },
      { text: "Commentaires", value: "notes" },
    ];

    /* Méthodes */
    const openDialogAsCreate = function () {
      createMode.value = true;
      openDialog.value = true;
    };
    const openDialogAsUpdate = function (uhcd: UHCD) {
      updateMode.value = true;
      editedUhcd.value = uhcd;
      openDialog.value = true;
    };

    /* Watchers */
    watch(
      () => uhcdData.pagination,
      () => {
        if (!uhcdData.firstLoad) {
          uhcdCrud.fetchUhcds();
        }
      },
      { deep: true }
    );
    watch(
      () => openDialog.value,
      (newValue: boolean, oldValue: boolean) => {
        if (!newValue && oldValue) {
          uhcdCrud.fetchUhcds();
        }
      }
    );

    return {
      openDialog,
      createMode,
      updateMode,
      editedUhcd,
      openDialogAsCreate,
      openDialogAsUpdate,
      ...toRefs(uhcdData),
      headers,
    };
  },
});
