export default class BaseObject {
    public _id: string;
    public creationDate: string;
    public updateDate: string;

    constructor(og?: BaseObject) {
        this._id = og ? og._id : "";
        this.creationDate = og ? og.creationDate : "";
        this.updateDate = og ? og.updateDate : "";
    }
}