import _axios from "@/axios";
import PaginationOptions from "@/models/general/PaginationOptions.model";
import SearchInfos from "@/models/general/SearchInfos.model";
import User from "@/models/user/User.model";
import { reactive } from "@vue/composition-api";
import { useQueryBuilder } from "@/composables/utils/queryBuilder"
import UserCriteria from "@/models/user/UserCriteria.model";
import UsersRights from "@/models/enums/UsersRights.enum";

export function UsersCRUD() {
  const { buildPostCriteriaModel } = useQueryBuilder();

  const usersData = reactive({
    loading: false,
    firstLoad: false,
    currentUser: new User(),
    users: new Array<User>(),
    searchInfos: new SearchInfos(),
    pagination: new PaginationOptions(),
    criteria: new UserCriteria(),
    rightsList: [
      { text: "Super-Admin", value: UsersRights.SUPER_ADMIN },
      { text: "Administrateur", value: UsersRights.ADMIN },
      { text: "Utilisateur", value: UsersRights.USER },
    ]
  });

  const userCrud = {
    getUserById: function (userId: string) {
      usersData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.get(`${process.env.VUE_APP_APIURL}/user/${userId}`)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            usersData.loading = false;
          })
      })
    },
    fetchUsers: function () {
      usersData.loading = true;
      return new Promise((resolve, reject) => {
        const filtersPayload = buildPostCriteriaModel(usersData.criteria, true, usersData.pagination);
        return _axios.post(`${process.env.VUE_APP_APIURL}/user/search`, filtersPayload)
          .then((response: any) => {
            usersData.searchInfos.filteredTotal = response.data.filteredTotal;
            usersData.searchInfos.overallTotal = response.data.overallTotal;
            usersData.searchInfos.totalPages = response.data.totalPages;
            usersData.users = response.data.data;
            if (usersData.firstLoad) {
              usersData.firstLoad = false;
            }
            resolve(response.data.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            usersData.loading = false;
          })
      })
    },
    updateUser: function (user: User) {
      usersData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.put(`${process.env.VUE_APP_APIURL}/user/profile/${user._id}`, user)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            usersData.loading = false;
          })
      })
    },
    updateUserAdmin: function (user: User) {
      usersData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.put(`${process.env.VUE_APP_APIURL}/user/admin-profile/${user._id}`, user)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            usersData.loading = false;
          })
      })
    },
    deleteUser: function (userId: string) {
      usersData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.delete(`${process.env.VUE_APP_APIURL}/user/${userId}`)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            usersData.loading = false;
          })
      })
    }
  };

  return {
    usersData,
    userCrud
  }
}