

































































































import { computed, defineComponent, onMounted, ref, toRefs } from "@vue/composition-api";
import Cotation from "@/models/cotations/Cotation.model";
import { CotationsCRUD } from "@/composables/cotations/cotations.crud";
import ConfirmDialog from "@/components/general/ConfirmDialog.vue";

export default defineComponent({
	components: {
		ConfirmDialog,
	},
	props: {
		open: Boolean,
		item: {
			type: Object,
			required: true,
		},
		updateMode: Boolean,
		createMode: Boolean,
	},
	setup(props, { emit }) {
		/* Import des API */
		const { cotationsData, cotationsCrud } = CotationsCRUD();

		/* Cycle de vie */
		onMounted(() => {
			if (props.updateMode) {
				editedItem.value = JSON.parse(JSON.stringify(props.item as Cotation));
			}
		});

		/* Variables */
		const editedItem = ref(new Cotation());
		const typesList = ["Cotation CCAM", "Cotation établissement"];
		const openConfirmDialog = ref(false);

		/* Computed */
		const openProxy = computed({
			get: function () {
				return props.open;
			},
			set: function (value: boolean) {
				emit("update:open", value);
			},
		});

		/* Méthodes */
		const closeDialog = function () {
			emit("update:createMode", false);
			emit("update:updateMode", false);
			editedItem.value = new Cotation();
			openProxy.value = false;
		};
		const saveItem = function () {
			editedItem.value.price = parseFloat(editedItem.value.price as any);
			if (props.createMode) {
				cotationsCrud.addCotation(editedItem.value).then(() => {
					closeDialog();
				});
			} else if (props.updateMode) {
				cotationsCrud.updateCotation(editedItem.value).then(() => {
					closeDialog();
				});
			}
		};
		const deleteItem = function () {
			cotationsCrud.deleteCotation(editedItem.value._id).then(() => {
				closeDialog();
			});
		};

		return {
			...toRefs(cotationsData),
			openProxy,
			closeDialog,
			editedItem,
			saveItem,
			typesList,
			deleteItem,
			openConfirmDialog,
		};
	},
});
