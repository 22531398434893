





























































































































import {
	computed,
	defineComponent,
	onBeforeMount,
	onMounted,
	ref,
} from "@vue/composition-api";
import store from "./store";
import ApiMessage from "@/components/general/ApiMessage.vue";
import { useLogin } from "@/composables/general/login";
import useRightsUtils from "@/composables/utils/rights";
import router from "./router";
import UserRights from "@/models/enums/UsersRights.enum";

export default defineComponent({
	components: {
		ApiMessage,
	},
	setup(props, { root }) {
		/* Import des API */
		const { logoutUser } = useLogin();
		const { hasRight } = useRightsUtils();

		/* Cycle de vie */
		onBeforeMount(() => {
			store.commit("initialiseStore");
		});
		onMounted(() => {
			if (!isMobile.value) {
				openDrawer.value = true;
			}
		});

		/* Variables */
		const openDrawer = ref(false);
		const miniDrawer = ref(false);

		/* Computed */
		const openApiMessageProxy = computed({
			get: function () {
				return store.getters.openApiMessage;
			},
			set: function (value: boolean) {
				store.commit("setOpenApiMessage", value);
			},
		});
		const isMobile = computed(function () {
			return root.$vuetify.breakpoint.xs;
		});
		const appTitle = computed(function () {
			return store.getters.title as string;
		});
		const appIcon = computed(function () {
			return store.getters.icon as string;
		});

		/* Méthodes */
		const goToProfile = function () {
			router.push({ name: "user-profile" });
		};
		const drawerAction = function () {
			if (isMobile.value) {
				openDrawer.value = !openDrawer.value;
			} else {
				miniDrawer.value = !miniDrawer.value;
			}
		};

		return {
			openApiMessageProxy,
			logoutUser,
			goToProfile,
			openDrawer,
			isMobile,
			miniDrawer,
			drawerAction,
			appTitle,
			appIcon,
			hasRight,
			UserRights,
		};
	},
});
