import ApiFilterTypes from "../enums/ApiFilterTypes.enum";
import BaseCriteria from "../general/BaseCriteria.model";

export default class ActeCriteria extends BaseCriteria {
  public shortName: string | null;
  public code: string | null;
  public type: string | null;
  public subtype: string | null;
  public isBillable: boolean | null;

  constructor() {
    super();
    this.shortName = null;
    this.code = null;
    this.type = null;
    this.subtype = null;
    this.isBillable = null;
    this.initFields();
  }

  initFields() {
    this.fields = [
      { name: "shortName", type: ApiFilterTypes.TEXT },
      { name: "code", type: ApiFilterTypes.TEXT },
      { name: "type", type: ApiFilterTypes.TEXT },
      { name: "subtype", type: ApiFilterTypes.TEXT },
      { name: "isBillable", type: ApiFilterTypes.BOOL },
    ]
  }
}