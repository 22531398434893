var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-needle")]),_c('span',[_vm._v("Actes | ")]),_c('span',{staticClass:"text-caption valign-middle"},[_vm._v(" "+_vm._s(_vm.searchInfos.filteredTotal)+" / "+_vm._s(_vm.searchInfos.overallTotal)+" créés ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.openFilters = true}}},[_c('v-icon',[_vm._v("mdi-filter")])],1),_c('v-btn',{attrs:{"color":"blue","icon":""},on:{"click":_vm.openDialogAsCreate}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-divider'),_c('v-card-text',{attrs:{"id":"actes-table"}},[_c('base-datatable',{staticClass:"table-clickable",attrs:{"headers":_vm.headers,"fixedHeaders":true,"items":_vm.actes,"containerId":"#actes-table","clickOnRow":true,"options":_vm.pagination,"serverItemsLength":_vm.searchInfos.filteredTotal,"itemClickRouterTarget":"dialog:actes","loading":_vm.loading,"itemsPages":[10, 25, 50, 100, 200]},on:{"update:options":function($event){_vm.pagination=$event},"open-dialog":function($event){return _vm.openDialogAsUpdate($event)}},scopedSlots:_vm._u([{key:"item.cims",fn:function(ref){
var item = ref.item;
return [(item.listCims && item.listCims.length > 0)?_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clipboard-list-outline")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((item.listCims),function(cim,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(cim)+" ")])],1)],1)}),1)],1):_c('span',[_vm._v("-")])]}},{key:"item.shortName",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])]),_c('span',[(_vm.criteria.shortName)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.highlightTerm(item.shortName, _vm.criteria.shortName))}}):_c('span',[_vm._v(_vm._s(item.shortName))])])]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('span',[(_vm.criteria.code)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.highlightTerm(item.code, _vm.criteria.code))}}):_c('span',[_vm._v(_vm._s(item.code))])])]}},{key:"item.notes",fn:function(ref){
var item = ref.item;
return [(item.notes)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-comment-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.notes))])]):_vm._e()]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [(item.price)?_c('span',[_vm._v(_vm._s(item.price)+"€")]):_c('span',[_vm._v("-")])]}},{key:"item.isBillable",fn:function(ref){
var item = ref.item;
return [(item.isBillable)?_c('span',[_c('v-chip',{attrs:{"color":"green","dark":"","small":""}},[_vm._v("Oui")])],1):(item.isBillable == false)?_c('span',[_c('v-chip',{attrs:{"small":""}},[_vm._v("Non")])],1):_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTypeLabel(item.type))+" ")]}},{key:"item.subtype",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getSubtypeLabel(item.type, item.subtype))+" ")]}}],null,true)})],1)],1),(_vm.openDialog)?_c('create-update-acte-dialog',{attrs:{"open":_vm.openDialog,"createMode":_vm.createMode,"updateMode":_vm.updateMode,"item":_vm.editedActe},on:{"update:open":function($event){_vm.openDialog=$event},"update:createMode":function($event){_vm.createMode=$event},"update:create-mode":function($event){_vm.createMode=$event},"update:updateMode":function($event){_vm.updateMode=$event},"update:update-mode":function($event){_vm.updateMode=$event}}}):_vm._e(),_c('actes-filters',{attrs:{"open":_vm.openFilters,"criteria":_vm.criteria},on:{"update:open":function($event){_vm.openFilters=$event},"update:criteria":[function($event){_vm.criteria=$event},_vm.applyFilters]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }