






























































import {
	computed,
	onBeforeMount,
	ref,
	watch,
	toRefs,
	defineComponent,
} from "@vue/composition-api";
import { useLogin } from "@/composables/general/login.ts";
import router from "@/router";
import store from "@/store";
import User from "../models/user/User.model";

export default defineComponent({
	name: "Login",
	setup() {
		/* Import des API */
		const { loginData, loginUser } = useLogin();

		/* Cycle de vie */

		/* Variables */
		const dialog = ref(true);
		const email = ref("");
		const password = ref("");

		/* Computed */

		/* Méthodes */
		const logUser = function () {
			const loggingUser = new User();
			loggingUser.email = email.value;
			loggingUser.password = password.value;
			loginUser(loggingUser)
				.then(() => {
					router.push({ name: "Home" });
				})
				.catch(() => {
					email.value = "";
					password.value = "";
				});
		};

		/* Watchers */

		return {
			dialog,
			email,
			password,
			logUser,
			...toRefs(loginData),
		};
	},
});
