












import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { useLogin } from "@/composables/general/login";
import router from "@/router";

export default defineComponent({
  setup() {
    /* Import des API */
    const { activateUser } = useLogin();

    /* Cycle de vie */
    onMounted(() => {
      activateUser(requestId).then(() => {
        loadingText.value =
          "Votre compte a été activé, vous allez pouvoir vous connecter...";
        setTimeout(function () {
          router.push({ name: "login" });
        }, 2000);
      });
    });
    /* Variables */
    const loadingText = ref("Activation de votre compte");
    const requestId = router.currentRoute.params.requestId;

    return {
      loadingText,
    };
  },
});
