import BaseObject from "../general/BaseObject.model";

export default class Cotation extends BaseObject{
  public name: string;
  public price: number | null;
  public code: string;
  public type: string;
  public notes: string;
  public isAdditional: boolean;

  constructor() {
    super();
    this.name = "";
    this.code = "";
    this.type = "";
    this.notes = "";
    this.price = null;
    this.isAdditional = false;
  }
}