import _axios from "@/axios";
import PaginationOptions from "@/models/general/PaginationOptions.model";
import SearchInfos from "@/models/general/SearchInfos.model";
import UHCD from "@/models/uhcd/UHCD.model";
import UHCDCriteria from "@/models/uhcd/UHCDCriteria.model";
import { reactive } from "@vue/composition-api";
import { useQueryBuilder } from "../utils/queryBuilder";

export function UhcdCRUD() {
  const { buildPostCriteriaModel } = useQueryBuilder();

  const uhcdData = reactive({
    loading: false,
    uhcds: new Array<UHCD>(),
    currentUhcd: new UHCD(),
    firstLoad: false,
    searchInfos: new SearchInfos(),
    pagination: new PaginationOptions({ itemsPerPage: 25 }),
    criteria: new UHCDCriteria(),
  });

  const uhcdCrud = {
    fetchUhcds: function () {
      uhcdData.loading = true;
      return new Promise((resolve, reject) => {
        const filtersPayload = buildPostCriteriaModel(uhcdData.criteria, true, uhcdData.pagination);
        return _axios.post(`${process.env.VUE_APP_APIURL}/uhcd/search`, filtersPayload)
          .then((response: any) => {
            uhcdData.searchInfos.filteredTotal = response.data.filteredTotal;
            uhcdData.searchInfos.overallTotal = response.data.overallTotal;
            uhcdData.searchInfos.totalPages = response.data.totalPages;
            uhcdData.uhcds = response.data.data;
            if (uhcdData.firstLoad) {
              uhcdData.firstLoad = false;
            }
            resolve(response.data.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            uhcdData.loading = false;
          })
      })
    },
    getUhcdById: function (id: string) {
      uhcdData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.get(`${process.env.VUE_APP_APIURL}/uhcd/${id}`)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            uhcdData.loading = false;
          })
      })
    },
    addUhcd: function (uhcd: UHCD) {
      uhcdData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.post(`${process.env.VUE_APP_APIURL}/uhcd/create`, uhcd)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            uhcdData.loading = false;
          })
      })
    },
    updateUhcd: function (uhcd: UHCD) {
      uhcdData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.put(`${process.env.VUE_APP_APIURL}/uhcd/${uhcd._id}`, uhcd)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            uhcdData.loading = false;
          })
      })
    },
    deleteUhcd: function (id: string) {
      uhcdData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.delete(`${process.env.VUE_APP_APIURL}/uhcd/${id}`)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            uhcdData.loading = false;
          })
      })
    }
  };

  return {
    uhcdData,
    uhcdCrud
  }
}