































import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
	name: "ConfirmDialog",
	props: {
		open: Boolean,
		loading: Boolean,
		confirmColor: {
			type: String,
			default: "primary",
		},
		dark: {
			type: Boolean,
			default: false,
		},
		makeAlert: Boolean,
		maxWidth: {
			type: Number,
			default: 500,
		},
		disableAction: Boolean,
	},
	setup(props, { emit }) {
		/* Computed */
		const dialog = computed({
			get: function () {
				return props.open;
			},
			set: function (value) {
				emit("update:open", value);
			},
		});

		/* Méthodes */
		const closeModal = function (isCancel: boolean) {
			dialog.value = false;
			if (isCancel) {
				emit("cancel");
			}
		};
		const sendConfirmEvent = function () {
			emit("confirm");
			closeModal(false);
		};

		return {
			dialog,
			closeModal,
			sendConfirmEvent,
		};
	},
});
