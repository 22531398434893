import Acte from "../actes/Acte.model";
import Cotation from "../cotations/Cotation.model";

export default class PricedAction {
  public name: string;
  public shortName: string;
  public code: string;
  public price: number;
  public halfOff: boolean;

  constructor(og?: Acte | Cotation) {
    this.name = og ? og.name : "";
    this.shortName = og && (og as Acte).shortName != undefined ? (og as Acte).shortName : "";
    this.code = og ? og.code : "",
      this.price = og ? og.price as number : 0
    this.halfOff = false;
  }
}