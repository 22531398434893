















































































































































































import User from "@/models/user/User.model";
import { defineComponent, onBeforeMount, ref, toRefs } from "@vue/composition-api";
import { UsersCRUD } from "@/composables/user/users.crud";
import store from "@/store";
import Clinic from "@/models/clinics/Clinic.model";
import CreateUpdateClinic from "@/components/user/CreateUpdateClinic.vue";

export default defineComponent({
	components: { CreateUpdateClinic },
	setup() {
		/* Import des API */
		const { usersData, userCrud } = UsersCRUD();

		/* Cycle de vie */
		onBeforeMount(() => {
			fetchUser();
		});

		/* Variables */
		const profileUser = ref(new User());
		const openClinicDialog = ref(false);
		const createMode = ref(false);
		const updateMode = ref(false);
		const editedClinic = ref(new Clinic());

		/* Méthodes */
		const fetchUser = function () {
			const loggedUser = store.getters.loggedUser as User;
			if (loggedUser._id) {
				userCrud.getUserById(loggedUser._id as string).then((response: any) => {
					profileUser.value = response;
				});
			}
		};
		const updateUser = function () {
			userCrud.updateUser(profileUser.value).then(() => {
				fetchUser();
			});
		};
		const saveClinic = function (newClinic: Clinic) {
			if (newClinic.isDefault) {
				wipeAllDefaultClinics();
			}
			const clinicIds = profileUser.value.clinics.map((c: Clinic) => c.id);
			const newClinicIndex = clinicIds.indexOf(newClinic.id);
			if (newClinicIndex > -1) {
				const clinicsClone = JSON.parse(
					JSON.stringify(profileUser.value.clinics)
				) as Clinic[];
				clinicsClone[newClinicIndex] = newClinic;
				profileUser.value.clinics = clinicsClone;
			} else {
				profileUser.value.clinics.push(newClinic);
			}
			updateUser();
		};
		const openDialog = function (clinic: Clinic) {
			if (clinic.id) {
				editedClinic.value = clinic;
				updateMode.value = true;
				createMode.value = false;
			} else {
				createMode.value = true;
				updateMode.value = false;
			}
			openClinicDialog.value = true;
		};
		const wipeAllDefaultClinics = function () {
			profileUser.value.clinics.forEach((clinic: Clinic) => {
				clinic.isDefault = false;
			});
		};
		const deleteClinic = function (id: string) {
			let indexToRemove = -1;
			profileUser.value.clinics.forEach((clinic: Clinic, index: number) => {
				if (clinic.id == id) {
					indexToRemove = index;
				}
			});
			profileUser.value.clinics.splice(indexToRemove, 1);
			updateUser();
		};
		const setClinicAsDefault = function (id: string) {
			wipeAllDefaultClinics();
			profileUser.value.clinics.forEach((clinic: Clinic) => {
				if (clinic.id == id) {
					clinic.isDefault = true;
				}
			});
			updateUser();
		};

		return {
			profileUser,
			...toRefs(usersData),
			updateUser,
			saveClinic,
			createMode,
			updateMode,
			openClinicDialog,
			editedClinic,
			openDialog,
			deleteClinic,
			setClinicAsDefault,
		};
	},
});
