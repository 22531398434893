export function useObjectUtils() {
  function cloneObject(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  function deepMerge(target: any, source: any) {
    const keys = Object.keys(source);
    let nextObj = cloneObject(target) as any;

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = source[key] as any;
      if (isNaN(parseInt(key))) {
        // la clé est un nom d'attribut
        if (typeof value == "object" && value != null) {
          const nestedNextObj = deepMerge(nextObj[key], value);
          nextObj[key] = nestedNextObj;
        } else {
          nextObj = { ...nextObj, [key]: value };
        }
      } else {
        // la clé est un index de tableau
        if (!nextObj) {
          nextObj = [];
        }
        nextObj[key] = value;
      }
    }

    return nextObj;
  }

  return {
    cloneObject,
    deepMerge,
  };
}
