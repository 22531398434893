










































import router from "@/router";
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  setup() {
    /* Variables */
    const oldPassword = ref("");
    const newPassword = ref("");
    const confirmNewPassword = ref("");
    const requestId = router.currentRoute.params.requestId;
    const showDifferentPasswordsAlert = ref(false);

    /* Méthodes */
    const sendNewPassword = function () {
      showDifferentPasswordsAlert.value = newPassword.value != confirmNewPassword.value;
    };

    return {
      oldPassword,
      newPassword,
      confirmNewPassword,
      showDifferentPasswordsAlert,
      sendNewPassword,
    };
  },
});
