import { ApiCondition } from "./ApiCondition.model";

export default class ApiFilter {
  public condition1: ApiCondition;
  public field: string;

  constructor() {
    this.condition1 = new ApiCondition();
    this.field = "";
  }
}
