







































import CreateUpdateAdmissionDialog from "@/components/admissions/CreateUpdateAdmissionDialog.vue";
import { defineComponent, onMounted, ref, toRefs, watch } from "@vue/composition-api";
import { AdmissionsCRUD } from "@/composables/admissions/admissions.crud";
import BaseDatatable from "@/components/general/BaseDatatable.vue";
import Admission from "@/models/admissions/Admission.model";

export default defineComponent({
  components: {
    CreateUpdateAdmissionDialog,
    BaseDatatable,
  },
  setup() {
    /* Import des API */
    const { admissionsData, admissionsCrud } = AdmissionsCRUD();

    /* Cycle de vie */
    onMounted(() => {
      admissionsData.firstLoad = true;
      admissionsCrud.fetchAdmissions();
    });

    /* Variables */
    const openDialog = ref(false);
    const createMode = ref(false);
    const updateMode = ref(false);
    const editedAdmission = ref(new Admission());
    const headers = [
      { text: "Code CCAM", value: "code" },
      { text: "Commentaires", value: "notes" },
    ];

    /* Méthodes */
    const openDialogAsCreate = function () {
      createMode.value = true;
      openDialog.value = true;
    };
    const openDialogAsUpdate = function (admission: Admission) {
      updateMode.value = true;
      editedAdmission.value = admission;
      openDialog.value = true;
    };

    /* Watchers */
    watch(
      () => admissionsData.pagination,
      () => {
        if (!admissionsData.firstLoad) {
          admissionsCrud.fetchAdmissions();
        }
      },
      { deep: true }
    );
    watch(
      () => openDialog.value,
      (newValue: boolean, oldValue: boolean) => {
        if (!newValue && oldValue) {
          admissionsCrud.fetchAdmissions();
        }
      }
    );

    return {
      openDialog,
      createMode,
      updateMode,
      editedAdmission,
      openDialogAsCreate,
      openDialogAsUpdate,
      ...toRefs(admissionsData),
      headers,
    };
  },
});
