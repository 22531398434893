import BaseObject from "../general/BaseObject.model";

export default class UHCD extends BaseObject{
  public notes: string;
  public name: string;

  constructor() {
    super();
    this.notes = "";
    this.name = "";
  }
}