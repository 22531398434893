export default class ApiError {
  public title: string;
  public message: string;
  public errorType: string;

  constructor() {
    this.title = "";
    this.message = "";
    this.errorType = "";
  }
}