import _axios from "@/axios";
import RequestConfig from "@/interfaces/RequestConfig.interface";
import Cotation from "@/models/cotations/Cotation.model";
import CotationCriteria from "@/models/cotations/CotationCriteria.model";
import PaginationOptions from "@/models/general/PaginationOptions.model";
import SearchInfos from "@/models/general/SearchInfos.model";
import { reactive } from "@vue/composition-api";
import { useQueryBuilder } from "../utils/queryBuilder";

export function CotationsCRUD() {
  const { buildPostCriteriaModel } = useQueryBuilder();

  const cotationsData = reactive({
    loading: false,
    cotations: new Array<Cotation>(),
    currentCotation: new Cotation(),
    firstLoad: false,
    searchInfos: new SearchInfos(),
    pagination: new PaginationOptions(),
    criteria: new CotationCriteria()
  });

  const cotationsCrud = {
    fetchCotations: function (requestConfig: RequestConfig) {
      cotationsData.loading = true;
      return new Promise((resolve, reject) => {
        const filtersPayload = buildPostCriteriaModel(cotationsData.criteria, requestConfig.paginate, cotationsData.pagination);
        return _axios.post(`${process.env.VUE_APP_APIURL}/cotations/search`, filtersPayload)
          .then((response: any) => {
            cotationsData.searchInfos.filteredTotal = response.data.filteredTotal;
            cotationsData.searchInfos.overallTotal = response.data.overallTotal;
            cotationsData.searchInfos.totalPages = response.data.totalPages;
            cotationsData.cotations = response.data.data;
            if (cotationsData.firstLoad) {
              cotationsData.firstLoad = false;
            }
            resolve(response.data.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            cotationsData.loading = false;
          })
      })
    },
    getCotationById: function (cotationId: string) {
      cotationsData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.get(`${process.env.VUE_APP_APIURL}/cotations/${cotationId}`)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            cotationsData.loading = false;
          })
      })
    },
    addCotation: function (cotation: Cotation) {
      cotationsData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.post(`${process.env.VUE_APP_APIURL}/cotations/create`, cotation)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            cotationsData.loading = false;
          })
      })
    },
    updateCotation: function (cotation: Cotation) {
      cotationsData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.put(`${process.env.VUE_APP_APIURL}/cotations/${cotation._id}`, cotation)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            cotationsData.loading = false;
          })
      })
    },
    deleteCotation: function (cotationId: string) {
      cotationsData.loading = true;
      return new Promise((resolve, reject) => {
        return _axios.delete(`${process.env.VUE_APP_APIURL}/cotations/${cotationId}`)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.data);
          })
          .finally(() => {
            cotationsData.loading = false;
          })
      })
    }
  };

  return {
    cotationsData,
    cotationsCrud
  }
}