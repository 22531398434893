enum ActeSubtypes {
  PLAIE_TETE = "PLAIE_TETE",
  PLAIE_FACE = "PLAIE_FACE",
  PLAIE_MAIN = "PLAIE_MAIN",
  PLAIE_HFACE = "PLAIE_HFACE",
  PLAIE_VEINE = "PLAIE_VEINE",
  PLAIE_TENDON = "PLAIE_TENDON",
  PLAIE_GYNECO = "PLAIE_GYNECO",
  TRAUMA_ONGLES = "TRAUMA_ONGLES",
  APPRIG_SSFRACTURE = "APPRIG_SSFRACTURE",
  APPRIG_AVFRACTURE = "APPRIG_AVFRACTURE",
  STRAPPING = "STRAPPING",
  ATTELLE_POSTURE_NNAMOVIBLE = "ATTELLE_POSTURE_NNAMOVIBLE",
  ATTELLE_POSTURE_AMOVIBLE = "ATTELLE_POSTURE_AMOVIBLE",
  PANSEMENT_INITIAL = "PANSEMENT_INITIAL",
  PANSEMENT_SECONDAIRE = "PANSEMENT_SECONDAIRE",
}

export default ActeSubtypes