import { render, staticRenderFns } from "./ActivityStatusChip.vue?vue&type=template&id=55f6d1e2&"
import script from "./ActivityStatusChip.vue?vue&type=script&lang=ts&"
export * from "./ActivityStatusChip.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports