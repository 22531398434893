import ApiDataTypes from "@/models/enums/ApiDataTypes.enum";
import ApiFilterOperators from "@/models/enums/ApiFilterOperators.enum";
import ApiFilterTypes from "@/models/enums/ApiFilterTypes.enum";
import SortDirections from "@/models/enums/SortDirections.enum";
import ApiFilter from "@/models/general/ApiFilter.model";
import CriteriaApi from "@/models/general/CriteriaApi.model";
import BaseCriteria from "@/models/general/BaseCriteria.model";
import PaginationOptions from "@/models/general/PaginationOptions.model";

export function useQueryBuilder() {
  function buildGetUrl(baseUrl: string, params: any) {
    let finalUrl = `${baseUrl}?`;
    const keys = Object.keys(params);
    keys.forEach((key: string, index: number) => {
      if (Array.isArray(params[key])) {
        params[key].forEach((element: any, indexParams: number) => {
          finalUrl += `${key}[${indexParams}]=${element}`;
          if (indexParams < params[key].length - 1) {
            finalUrl += "&";
          }
        });
      } else {
        finalUrl += `${key}=${params[key]}`;
      }
      if (index < keys.length - 1) {
        finalUrl += "&";
      }
    });
    return finalUrl;
  }

  function buildPostCriteriaModel(
    criteria: BaseCriteria,
    paginate: boolean = true,
    pagination: PaginationOptions
  ) {
    const apiModel = new CriteriaApi();
    const MAX_RECORDS = 1000000;
    if (paginate) {
      apiModel.startRow = pagination.itemsPerPage * (pagination.page - 1);
      apiModel.endRow = apiModel.startRow + pagination.itemsPerPage;
    } else {
      apiModel.startRow = 0;
      apiModel.endRow = MAX_RECORDS;
    }
    pagination.sortBy.forEach((property: string, index: number) => {
      const isSortDesc = pagination.sortDesc[index];
      apiModel.sorts.push({
        dir: isSortDesc ? SortDirections.DESC : SortDirections.ASC,
        field: property,
      });
    });
    if (pagination.forcedSorts) {
      pagination.forcedSorts.forEach((s: { field: string; desc: boolean }) => {
        apiModel.sorts.push({
          field: s.field,
          dir: s.desc ? SortDirections.DESC : SortDirections.ASC,
        });
      });
    }

    // on retire les fields en doublon (on prend le premier disponible par défaut, s'il y en a au moins deux avec des valeurs différentes, le bug doit être traité plus en amont)
    const presentFields = criteria.fields.map(
      (e: { name: string; type: string }) => e.name
    );
    const removeIndexesArray = [] as number[];
    presentFields.forEach((field: string, index: number) => {
      const firstOccurenceIndex = presentFields.indexOf(field);
      if (index > firstOccurenceIndex) {
        removeIndexesArray.push(index);
      }
    });
    removeIndexesArray.forEach((indexToRemove: number) => {
      criteria.fields.splice(indexToRemove, 1);
    });

    criteria.fields.forEach((field: { name: string; type: string }) => {
      const newFilter = new ApiFilter();
      const fieldName = field.name as keyof BaseCriteria;
      const criteriaFieldValue = field.name
        .split(".")
        .reduce(function (p: any, prop: string) {
          return p[prop];
        }, criteria);
      newFilter.field = field.name;
      if (
        field.type == ApiDataTypes.DATE &&
        (criteria.dateFrom || criteria.dateTo)
      ) {
        newFilter.condition1.dateFrom = criteria.dateFrom;
        if (criteria.dateTo != criteria.dateFrom) {
          newFilter.condition1.dateTo = criteria.dateTo;
        }
        newFilter.condition1.filterType = ApiFilterTypes.DATE;
        newFilter.condition1.type =
          criteria.dateTo != criteria.dateFrom
            ? ApiFilterOperators.RANGE
            : ApiFilterOperators.EQ;
        apiModel.filters.push(newFilter);
      } else if (criteriaFieldValue != null) {
        if (field.type == ApiDataTypes.NUMBER) {
          newFilter.condition1.filter =
            criteriaFieldValue != -1
              ? (criteriaFieldValue as number).toString()
              : null;
          newFilter.condition1.filterType = ApiFilterTypes.NUMBER;
          newFilter.condition1.type =
            criteriaFieldValue != -1
              ? ApiFilterOperators.EQ
              : ApiFilterOperators.EMPTY;
        } else if (field.type == ApiDataTypes.SET) {
          let setValues = criteriaFieldValue;
          if (
            typeof criteriaFieldValue != "undefined" &&
            typeof criteriaFieldValue != "object"
          ) {
            setValues = [criteriaFieldValue];
          }
          newFilter.condition1.values = (setValues as number[]).map(
            (e: number) => e.toString()
          );
          newFilter.condition1.filterType = ApiFilterTypes.SET;
          newFilter.condition1.type = ApiFilterOperators.CONTAINS;
        } else if (field.type == ApiDataTypes.TEXT) {
          newFilter.condition1.filter = criteriaFieldValue as string;
          newFilter.condition1.filterType = ApiFilterTypes.TEXT;
          newFilter.condition1.type = ApiFilterOperators.CONTAINS;
        }
        else if (field.type == ApiDataTypes.BOOL) {
          newFilter.condition1.bool = criteriaFieldValue as boolean;
          newFilter.condition1.filterType = ApiFilterTypes.BOOL;
          newFilter.condition1.type = ApiFilterOperators.EQ;
        }
        apiModel.filters.push(newFilter);
      }
    });

    return apiModel;
  }

  return {
    buildGetUrl,
    buildPostCriteriaModel,
  };
}
