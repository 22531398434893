enum ApiFilterOperators {
  EQ = "equals",
  NEQ = "notEqual",
  CONTAINS = "contains",
  NOT_CONTAINS = "notContains",
  START_WITH = "startsWith",
  END_WITH = "endWith",
  EMPTY = "isEmpty",
  NOT_EMPTY = "isNotEmpty",
  LT = "lessThan",
  LTE = "lessThanOrEqual",
  GT = "greaterThan",
  GTE = "greaterThanOrEqual",
  RANGE = "inRange",
}

export default ApiFilterOperators;
