import ApiFilter from "./ApiFilter.model";
import ApiSort from "./ApiSort.model";

export default class CriteriaApi {
  public startRow: number;
  public endRow: number;
  public sorts: Array<ApiSort>;
  public filters: Array<ApiFilter>;

  constructor() {
    this.startRow = 0;
    this.endRow = 0;
    this.sorts = new Array<ApiSort>();
    this.filters = new Array<ApiFilter>();
  }
}
