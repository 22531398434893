enum ActeTypes {
  PLAIES = "PLAIES",
  IMMOBILISATIONS = "IMMOBILISATIONS",
  REDUCTIONS = "REDUCTIONS",
  LUXATIONS = "LUXATIONS",
  BRULURES = "BRULURES",
  ABCES_PONCTIONS = "PONCTIONS_DRAINAGES",
  CORPS_ETRANGER = "CORPS_ETRANGER",
  REA = "REA",
  GASTRO = "GASTRO",
  URO = "URO",
  ORL = "ORL",
  OPHTALMO = "OPHTALMO",
  INFILT = "INFILT",
  ECHOGRAPHIE = "ECHOGRAPHIE",
  JUSTICE = "FRAIS_JUSTICE",
  CONSULTATION = "CONSULTATION",
}

export default ActeTypes