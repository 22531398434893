var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('resume-toolbar',{attrs:{"has-actions":"","elevation":0},scopedSlots:_vm._u([{key:"title",fn:function(){return [(!_vm.isMobile)?_c('div',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-book-open-variant")]),_c('span',[_vm._v("Mon activité | ")])],1):_vm._e(),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.searchInfos.filteredTotal)+" / "+_vm._s(_vm.searchInfos.overallTotal)+" dossiers saisis ")])]},proxy:true},{key:"actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":function($event){_vm.importDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cloud-upload")])],1)]}}])},[_c('span',[_vm._v("Importer mes dossiers à partir d'un fichier CSV")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"green"},on:{"click":function($event){_vm.loadingDownload = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-excel")])],1)]}}])},[_c('span',[_vm._v("Télécharger mon activité au format CSV")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":function($event){_vm.openFilters = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter-outline")])],1)]}}])},[_c('span',[_vm._v("Filtres")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":_vm.applyFilters}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Actualiser mon activité")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":function($event){_vm.openDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Ajouter une entrée dans mon journal d'activité")])])]},proxy:true},{key:"actions.collapsed",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"secondary"},on:{"click":function($event){_vm.openDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Ajouter une entrée dans mon journal d'activité")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"secondary"},on:{"click":_vm.applyFilters}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Actualiser mon activité")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"secondary"},on:{"click":function($event){_vm.openFilters = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter-outline")])],1)]}}])},[_c('span',[_vm._v("Filtres")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","dark":"","color":"green"},on:{"click":function($event){_vm.loadingDownload = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-excel")])],1)]}}])},[_c('span',[_vm._v("Télécharger mon activité au format CSV")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"secondary"},on:{"click":function($event){_vm.importDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cloud-upload")])],1)]}}])},[_c('span',[_vm._v("Importer mes dossiers à partir d'un fichier CSV")])])]},proxy:true}],null,true)}),_c('v-divider'),_c('v-card-text',{attrs:{"id":"activities-table"}},[_c('base-datatable',{staticClass:"table-clickable",attrs:{"headers":_vm.headers,"fixedHeaders":true,"items":_vm.activities,"containerId":"#activities-table","clickOnRow":true,"options":_vm.pagination,"serverItemsLength":_vm.searchInfos.filteredTotal,"loading":_vm.loading,"itemsPages":[10, 25, 50, 100, 200],"itemClickRouterTarget":"dialog"},on:{"update:options":function($event){_vm.pagination=$event},"open-dialog":function($event){return _vm.openActivity($event)}},scopedSlots:_vm._u([{key:"item.dossier",fn:function(ref){
var item = ref.item;
return [(item.locked)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-lock")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.dossier))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('activity-status-chip',{attrs:{"status":item.status}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('activity-facturation-type',{attrs:{"facturationType":item.type}})]}},{key:"item.treatedAt",fn:function(ref){
var item = ref.item;
return [(item.treatedAt)?_c('span',[_vm._v(" "+_vm._s(_vm.formatDateTimeToString(item.treatedAt))+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.totalPaid",fn:function(ref){
var item = ref.item;
return [(item.totalPaid)?_c('span',[_vm._v(_vm._s(item.totalPaid)+"€")]):_c('span',[_vm._v("-")])]}},{key:"item.totalBilled",fn:function(ref){
var item = ref.item;
return [(item.totalBilled)?_c('span',[_vm._v(_vm._s(item.totalBilled.toFixed(2))+"€")]):_c('span',[_vm._v("-")])]}},{key:"item.billedList",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.billedList.map(function (e) { return e.code; }).join(", "))+" ")]}}],null,true)})],1)],1),(_vm.openDialog)?_c('create-update-activity-dialog',{attrs:{"open":_vm.openDialog,"item":_vm.editedActivity},on:{"update:open":function($event){_vm.openDialog=$event}}}):_vm._e(),_c('activity-filters',{attrs:{"open":_vm.openFilters,"criteria":_vm.criteria},on:{"update:open":function($event){_vm.openFilters=$event},"update:criteria":function($event){_vm.criteria=$event}}}),(_vm.loadingDownload)?_c('activity-downloader',{attrs:{"loading":_vm.loadingDownload},on:{"update:loading":function($event){_vm.loadingDownload=$event}}}):_vm._e(),(_vm.importDialog)?_c('activity-import-dialog',{attrs:{"open":_vm.importDialog},on:{"update:open":function($event){_vm.importDialog=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }