














































































































































































































import User from "@/models/user/User.model";
import { defineComponent, onBeforeMount, ref, toRefs, watch } from "@vue/composition-api";
import { UsersCRUD } from "@/composables/user/users.crud";
import Clinic from "@/models/clinics/Clinic.model";
import CreateUpdateClinic from "@/components/user/CreateUpdateClinic.vue";
import router from "@/router";

export default defineComponent({
	components: { CreateUpdateClinic },
	setup() {
		/* Import des API */
		const { usersData, userCrud } = UsersCRUD();

		/* Cycle de vie */
		onBeforeMount(() => {
			usersData.firstLoad = true;
			fetchUser();
		});

		/* Variables */
		const profileUser = ref(new User());
		const openClinicDialog = ref(false);
		const createMode = ref(false);
		const updateMode = ref(false);
		const editedClinic = ref(new Clinic());
		const userId = router.currentRoute.params.id;

		/* Méthodes */
		const fetchUser = function () {
			userCrud.getUserById(userId).then((response: any) => {
				profileUser.value = response;
				setTimeout(function () {
					usersData.firstLoad = false;
				}, 10);
			});
		};
		const updateUser = function () {
			userCrud.updateUserAdmin(profileUser.value).then(() => {
				fetchUser();
			});
		};
		const saveClinic = function (newClinic: Clinic) {
			if (newClinic.isDefault) {
				wipeAllDefaultClinics();
			}
			const clinicIds = profileUser.value.clinics.map((c: Clinic) => c.id);
			const newClinicIndex = clinicIds.indexOf(newClinic.id);
			if (newClinicIndex > -1) {
				const clinicsClone = JSON.parse(
					JSON.stringify(profileUser.value.clinics)
				) as Clinic[];
				clinicsClone[newClinicIndex] = newClinic;
				profileUser.value.clinics = clinicsClone;
			} else {
				profileUser.value.clinics.push(newClinic);
			}
			updateUser();
		};
		const openDialog = function (clinic: Clinic) {
			if (clinic.id) {
				editedClinic.value = clinic;
				updateMode.value = true;
				createMode.value = false;
			} else {
				createMode.value = true;
				updateMode.value = false;
			}
			openClinicDialog.value = true;
		};
		const wipeAllDefaultClinics = function () {
			profileUser.value.clinics.forEach((clinic: Clinic) => {
				clinic.isDefault = false;
			});
		};
		const deleteClinic = function (id: string) {
			let indexToRemove = -1;
			profileUser.value.clinics.forEach((clinic: Clinic, index: number) => {
				if (clinic.id == id) {
					indexToRemove = index;
				}
			});
			profileUser.value.clinics.splice(indexToRemove, 1);
			updateUser();
		};
		const setClinicAsDefault = function (id: string) {
			wipeAllDefaultClinics();
			profileUser.value.clinics.forEach((clinic: Clinic) => {
				if (clinic.id == id) {
					clinic.isDefault = true;
				}
			});
			updateUser();
		};
		const goToUsers = function () {
			router.push({ name: "users" });
		};
		const changeEmailConfirmation = function () {
			if (!profileUser.value.emailConfirmed) {
				profileUser.value.emailConfirmed = true;
				updateUser();
			}
		};
		const deleteUser = function () {
			userCrud.deleteUser(profileUser.value._id as string).then(() => {
				router.push({ name: "users" });
			});
		};

		/* Watchers */
		watch(
			() => profileUser.value.isActivated,
			(newValue: boolean, oldValue: boolean) => {
				if (!usersData.firstLoad && newValue != oldValue) {
					updateUser();
				}
			}
		);
		watch(
			() => profileUser.value.emailConfirmed,
			(newValue: boolean, oldValue: boolean) => {
				if (!usersData.firstLoad && newValue && !oldValue) {
					updateUser();
				}
			}
		);

		return {
			profileUser,
			...toRefs(usersData),
			updateUser,
			saveClinic,
			createMode,
			updateMode,
			openClinicDialog,
			editedClinic,
			openDialog,
			deleteClinic,
			setClinicAsDefault,
			goToUsers,
			changeEmailConfirmation,
			deleteUser,
		};
	},
});
